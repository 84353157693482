<template>
  <div class="app-content">
      <div class="app-content__header">
          <div class="row">
              <h1 class="app-content__title">{{ $t($route.name) }}  {{ $store.state.dispatch.delivery != null ? `#${$store.state.dispatch.delivery._source.deliveryNO}` : "" }}</h1>

              <div class="app-content__header-action">
                <div v-if="$store.state.dispatch.isPreloaded && delivery != null">
                  <lh-button v-if="delivery._source.document && delivery._source.document.s3.bucketName !== ''
                  && delivery._source.document.s3.fileName !== ''" size="large" type="primary" @click="getDeliveryNote">
                      {{ $t('Delivery Note') }}
                  </lh-button>
                </div>
                <div v-if="$store.state.dispatch.isPreloaded && delivery != null">
                  <lh-button v-if="$store.state.dispatch.delivery._source.deliveryType == 'Delivered' && ['Loading', 'Finished', 'Arrived', 'Shipping'].includes($store.state.dispatch.delivery._source.status)" size="large" type="primary" @click="redirectToTracking">
                    {{ $t('Tracking') }}
                  </lh-button>
                </div>
                <div v-if="$store.state.dispatch.isPreloaded && delivery != null">
                  <lh-button v-if="(delivery._source.status === 'Scheduled' || delivery._source.status === 'Loading')" size="large" type="primary" @click="() => showResetDeliveryAlert = true">
                    {{ $t('Reset delivery') }}
                  </lh-button>
                </div>
                <div v-if="$store.state.dispatch.isPreloaded && delivery != null">
                  <lh-button v-if="(delivery._source.status ===  commonClasses.deliveryStatus.Shipping)" size="large" type="primary" @click="showDivertModal = true">
                    {{ $t('Divert') }}
                  </lh-button>
                </div>
                <div v-if="$store.state.dispatch.isPreloaded && delivery != null">
                  <lh-button v-if="delivery && delivery._source && delivery._source.document && delivery._source.document.s3.bucketName !== ''" size="large" type="primary" @click="printDeliveryNote(delivery)">
                    {{ $t('Print DN') }}
                  </lh-button>
                </div>
                <lh-button v-if="$store.state.dispatch.delivery != null && isDisabled" size="large" type="primary" @click="unblockDelivery">
                    {{ $t('Unblock delivery') }}
                </lh-button>
                
                <lh-button size="large" type="primary" @click="dispatchAction(false)" :disabled="isDisabled">
                    {{ $t('DISPATCH') }}
                </lh-button>
                <lh-button v-if="isWaste" size="large" type="primary" @click="downloadDAP_Blank">
                    {{ $t('DAP Download') }}
                </lh-button>
              </div>

      </div>
    </div>
    <div class="app-content__content truck">
      <div class="tabButton">
        <!--lh-button-tabs class="data-list__filters-btns buttons white"
          :name='tabs'
          :label='tabs' active='Dispatch' size="large"
          v-lhevent:tabClicked="changeTab"></lh-button-tabs-->

          </div>
        <div class="row">

        <PreloadedDeliveryData v-if="$store.state.dispatch.isPreloaded && delivery != null"/>

        <VehicleAssignations />

        <Materials v-if="steps.materials && !isWaste" />

        <!-- Waste must show this component before WasteMaterials-->
        <WeighbridgesOut v-if="steps.weighbridges 
              && (orderType == orderTypeList.OUTBOUND || orderType == orderTypeList.TRANSFEROUT)
              && isWaste" />

        <WasteMaterials v-if="steps.materials 
              && (orderType == orderTypeList.INBOUND || orderType == orderTypeList.OUTBOUND)
              && isWaste" @showControlSection="showControlSectionMethod" />

        <div v-if="showControlSection">
          <Control v-if="steps.materials && steps.control
                && orderType == orderTypeList.INBOUND
                && isWaste" />
        </div>

        <TransferOutWaste v-if="steps.materials  
              && orderType == orderTypeList.TRANSFEROUT
              && isWaste
              && $store.state.dispatch.exitWeight.weight > 0" flowType="TRANSFER_OUT" flowTypeReference="W_IntraCo" />

        <TransferInWaste v-if="steps.materials 
              && orderType == orderTypeList.TRANSFERIN
              && isWaste" />

        <WeighbridgesIn v-if="steps.weighbridges 
              && (orderType == orderTypeList.INBOUND || orderType == orderTypeList.TRANSFERIN)
              && !isWaste" />

        <WeighbridgesWIn v-else-if="steps.weighbridges
              && (orderType == orderTypeList.INBOUND || orderType == orderTypeList.TRANSFERIN)
              && isWaste" />

        <!--<WeighbridgesOut v-else-if="steps.weighbridges 
              && (orderType == orderTypeList.OUTBOUND || orderType == orderTypeList.TRANSFEROUT)"
            />-->

        <WeighbridgesOut v-else-if="steps.weighbridges 
              && (orderType == orderTypeList.OUTBOUND || orderType == orderTypeList.TRANSFEROUT || orderType == 'Trading')
              && !isWaste" />

        <CashSales v-if="steps.cash" />

        <Attachments v-if="steps.attachments" />

        <Notes v-if="steps.notes" />

        <DeliveryTour v-if="steps.materials 
              && orderType == orderTypeList.OUTBOUND
              && delivery
              && delivery._source.Tour.returnTrip.route !== 'noRoute'" :delivery="delivery" :plant="plantParams" />

        <AuditData v-if="steps.audit" />

        <Dialog v-model:visible="showDispatchMessage" :header="$t('Dispatch message')" :draggable="false"
          style="width: 30rem">

          <div style="text-align: center"> <i class="pi pi-exclamation-triangle is-size-3 mr-3"></i></div>
          <div v-for="message in dispatchMessageData">
            <div class="p-4">
              <div><strong>{{$t(message.title)}}</strong></div>
              <div>{{$t(message.message)}}</div>
            </div>
          </div>
        </Dialog>

        <Dialog v-model:visible="showDispatchControlMessage" :header="$t('Dispatch message')" :draggable="false"
          style="width: 30rem">
          <div style="text-align: center"> <i class="pi pi-exclamation-triangle is-size-3 mr-3"></i></div>
          <div v-for="message in dispatchControlMessageData">
            <div class="p-4">
              <div v-if="message.type === 'CONTROL'">
                <div><strong> {{ $t(message.title) }}</strong></div>
                <div>{{ $t(message.message) }}</div>
                <div>{{ $t(message.message1) }}</div>
                <div>{{ $t(message.message2) }}</div>
                <div class="is-flex is-flex-direction-column"></div>

                <div class="is-flex is-flex-direction-row mt-3 mb-3" style="float:right">
                  <div class="is-flex is-flex-direction-column mr-2">
                    <Button class="p-button btn mr-2" type="button" label="Yes" severity="secondary"
                      @click="continueDispatch()">{{ $t('YES') }}</Button>
                  </div>

                  <div class="is-flex is-flex-direction-column">
                    <Button class="btn btn--primary" type="button" label="No"
                      @click="closeDispatchControlMessage()">{{ $t('NO') }}</Button>
                  </div>
                </div>
              </div>
             </div>
             </div>
            </Dialog>
            <Dialog v-if="delivery != null" v-model:visible="showDivertModal" :style="{ width: '95rem' }" :header="$t('Divert') + ' #' + delivery._source.deliveryNO">
			        <DivertModal :delivery="delivery"></DivertModal>
		        </Dialog>
		        <Dialog v-if="delivery != null" v-model:visible="showResetDeliveryAlert" modal header="Reset delivery fields?" :style="{ width: '40rem' }">
              <div class="alert_past_date">
                <span class="p-text-secondary block mb-5">{{ $t('If you click on YES, you will reset the delivery fields values') }}</span>
                <div class="alert_past_date_actions">
                <lh-button style="width: 150px;" @click="showResetDeliveryAlert = false">
                  {{ $t('No') }}
                </lh-button>
                <lh-button style="width: 150px;" type="primary" @click="resetDeliveryData(delivery._id)">
                  {{ $t('Yes') }}
                </lh-button>
              </div>
          </div>
        </Dialog>
        </div>
      </div>
  </div>

</template>

<script setup>
import { app } from '@/main.js';
import VehicleAssignations from '@/components/dispatch/steps/VehicleAssignations';
import Materials from '@/components/dispatch/steps/Materials';
import WeighbridgesIn from '@/components/dispatch/steps/WeighbridgesIn';
import WeighbridgesWIn from '@/components/dispatch/steps/WeighbridgesWIn';
import WeighbridgesOut from '@/components/dispatch/steps/WeighbridgesOut';
import Attachments from '@/components/dispatch/steps/Attachments';
import Notes from '@/components/dispatch/steps/Notes';
import DeliveryTour from '@/components/dispatch/steps/DeliveryTour.vue';
import AuditData from '@/components/dispatch/steps/AuditData';
import CashSales from '@/components/dispatch/steps/CashSales';
import WasteMaterials from '@/components/dispatch/steps/MaterialsWaste';
import Control from '@/components/dispatch/steps/Control';
import TransferOutWaste from '@/components/dispatch/steps/TransferOutWaste';
import TransferInWaste from '@/components/dispatch/steps/TransferInWaste';
import Dialog from 'primevue/dialog';

import DeliveryService from '@/services/api/delivery';
import deliveryNoteService from '@/services/api/deliveryNote';
import PreloadedDeliveryData from '@/components/dispatch/utils/PreloadedDeliveryData.vue';
import TrackingService from '@/services/api/tracking';
import DivertModal from '@/components/Deliveries/data-table/DivertModal.vue';
import commonClasses from "@/store/commonClasses.js";

import WasteService from "@/services/api/waste";  
</script>

<script>
export default {
  name: 'DispatchView',
  components: {
      VehicleAssignations,
      Materials,
      CashSales,
      WeighbridgesIn,
      WeighbridgesOut,
      Attachments,
      Notes,
      AuditData,
      DeliveryTour,
      WasteMaterials,
      TransferOutWaste,
      TransferInWaste,
      Control,
  },
  data: function () {
      return {
        tabs: ["Dispatch"],
          steps: {
            materials: false,
            control:false,
            weighbridges: false,
            cash: false,
            attachments: false,
            notes: false,
            audit: false
          },
        showControlSection:false,
        showDispatchMessage:false,
        dispatchMessageData:[],
        showDispatchControlMessage:false,
        dispatchControlMessageData:[],
        treatmentCodeMandatory: true,
        orderTypeList: {
          OUTBOUND: 'Outbound',
          INBOUND: 'Inbound',
          TRANSFERIN: 'Transfer In',
          TRANSFEROUT: 'Transfer Out'
        },
        attachments: [],
        urlDeliveryId: this.$route.query.id,
        user: JSON.parse(sessionStorage.getItem("user")),
        trackingBtnCondition: ['Loading', 'Finished', 'Arrived', 'Shipping'],
        showResetDeliveryAlert: false,
        showDivertModal: false
      }
  },
  watch: {
    '$store.state.dispatch.orderType'(newValue, oldValue) {
        this.steps.materials = false;
        this.steps.weighbridges = false;
        this.steps.cash = false;
        this.steps.attachments = false;
        this.steps.notes = false;
        this.steps.audit = false;
        this.steps.control=false;
        this.showControlSection=false;
        if (this.$store.state.dispatch.currentTruck != null && this.orderType == this.orderTypeList.OUTBOUND) this.goToStep(3)
    },
    '$store.state.dispatch.delivery'(newValue, oldValue) {
      // Si hay delivery cargada en el store para mostrar todas las cajas
      if(newValue && newValue._source) {
        this.goToStep(2);
        if (newValue._source.contract.documentType == 'ZCSA' || newValue._source.contract.documentType == 'ZONC' ) this.goToStep(4)
      } else {
        this.goToStep(1);
      }
    },
    '$store.state.dispatch.currentTruck'(newValue, oldValue) {
      if (newValue != null) {
      
        if ((this.orderType == this.orderTypeList.OUTBOUND || this.orderType == this.orderTypeList.TRANSFEROUT) 
          && this.delivery == null) {
            this.goToStep(3)
          }
      }
    },
  },
  computed: {
    isPreloaded() {
      return this.urlDeliveryId != null
    },
    orderType() {
      return this.$store.state.dispatch.orderType
    },
    isWaste() {
      return this.$store.state.dispatch.isWaste
    },
    delivery() {
      return this.$store.state.dispatch.delivery
    },
    isDisabled() {
      if (this.$store.state.dispatch.delivery === null) {
        return true
      }

      if(!this.$store.state.dispatch.reference){
        return true
      }

      const material = this.$store.state.dispatch.delivery._source.material[0]

      const isWasteInbound = this.isWaste && this.orderType === this.orderTypeList.INBOUND
      const isWasteOutbound = this.isWaste && this.orderType === this.orderTypeList.OUTBOUND
      const isWasteTransferIn = this.isWaste && this.orderType === this.orderTypeList.TRANSFERIN
      const isWasteTransferOut = this.isWaste && this.orderType === this.orderTypeList.TRANSFEROUT

      if (isWasteInbound && material.dap === undefined) {
        return true
      }

      if (isWasteTransferIn && material.wasteTransferIn === undefined) {
        return true
      }

      const isInboundWasteDisable = isWasteInbound &&
                                    ( material.dap.destinationInfo === undefined ||
                                      material.dap.treatmentInfo === undefined )

      const isOutboundWasteDisable = isWasteOutbound &&
                                     ( material.originOutbound === undefined ||
                                       material.treatmentOutbound === undefined ||
                                       material.qualificationOutbound === undefined ||
                                       material.referenceOutbound === undefined ||
                                       material.referenceOutbound.length === 0 )

      const isTransferInWasteDisable = isWasteTransferIn &&
                                       ( material.wasteTransferIn.destination === undefined ||
                                         material.wasteTransferIn.treatment === undefined )

      const isTransferOutWasteDisable = isWasteTransferOut &&
                                        ( material.originOutbound === undefined ||
                                          material.treatmentOutbound === undefined ||
                                          material.qualificationOutbound === undefined ||
                                          material.referenceOutbound === undefined ||
                                          material.referenceOutbound.length === 0 )

      const entryWeightValidation = this.orderType != this.orderTypeList.TRANSFERIN 
                                    ? this.$store.state.dispatch.entryWeight.date === null
                                    : this.$store.state.dispatch.transferInWeight.date === null
      
      return entryWeightValidation || 
              isInboundWasteDisable ||
              isOutboundWasteDisable || 
              isTransferInWasteDisable || 
              isTransferOutWasteDisable
    }
  },
  mounted() {
    this.$store.getters.getPlantWasteEnable === '1' ? this.tabs.push('Storage Movement') : ''
  },
  methods: {
    showControlSectionMethod(value){
      console.log('showControlSectionMethod >>> ', value )
      this.showControlSection = value;
    },
    autoPrint(data) { deliveryNoteService.printDN(data) },
    printDeliveryNote(delivery) {
			let loader = this.$loading.show()
			const printer = this.user.terminal.printers ? this.user.terminal.printers.find((p) => p.code === "DN_EPOD" || p.code === "DN" ) : null;
			if(printer) {
				const data = { bucket: delivery._source.document.s3.bucketName, key: delivery._source.document.s3.fileName, printer: printer };
				this.autoPrint(data);
				this.$notify({
					title: this.$t('Printing'),
					text: this.$t('Document sent to printer'),
					type: 'success',
					duration: 3000
				});
			} else {
				this.$notify({
					title: this.$t('Printing Error'),
					text: this.$t('No printers are currently associated with your terminal.'),
					type: 'error',
					duration: 3000
				});
			}
			loader.hide()
		},
		redirectToTracking() {
			let loader = this.$loading.show();
			let filters = {
				"dn_number": this.delivery._source.deliveryNO + "",
				"id_order": null,
				"plant": this.delivery._source.material[0].plant,
				"truck": null,
        "plants": this.user.plants.map(plant => plant.code)
			};
			TrackingService.getTrackingUrl(filters).then((response) => {
				window.open(response, "_blank");
				loader.hide();
			}).catch(error => {
				loader.hide();
				this.$notify({
					title: this.$t("Something went wrong, try again later"),
					type: "error",
					duration: -1,
					closeOnClick: true
				})
			});
		},
    async resetDeliveryData(id) {
			try {
				let deliveryData = { id }
				if (this.delivery) {
					deliveryData.status = this.delivery._source.status
					if (deliveryData.status = "Loading") deliveryData.truckId = this.delivery._source.logistic.truck.vehicleId
				}
				let loader = this.$loading.show()
				this.showResetDeliveryAlert = false
				if (this.$store.state.mode === 'Edge') {
					const deliveryResetLocal = await DeliveryService.resetDeliveryDataLocal(deliveryData, this.user)
					if (deliveryResetLocal && deliveryResetLocal.data && deliveryResetLocal.data.msg === 'Updated') {
						this.$notify({
							title: this.$t("Delivery reset"),
							text: `Delivery data was successfully reset locally`,
							type: 'success',
						})
						this.$emit('checkData', 'exit')
					} else {
						this.$notify({
							title: this.$t("Delivery reset"),
							text: `Couldn't reset the delivery data locally`,
							type: 'error',
						})
					}
				}
				const response = await DeliveryService.resetDeliveryData(deliveryData, this.user)
				if (response && response.status === 200) {
					this.$notify({
						title: this.$t("Delivery reset"),
						text: `Delivery data was successfully reset`,
						type: 'success',
					})
					this.$emit('checkData', 'exit')
				} else {
					this.$notify({
						title: this.$t("Delivery reset"),
						text: `Couldn't reset the delivery data`,
						type: 'error',
					})
				}
				loader.hide()
			} catch (error) { console.log('error reset develivery data: ', error) }
		},
    async unblockDelivery() {
			let loader = this.$loading.show()
			let delivery = this.delivery
			await DeliveryService.unblockDelivery(delivery, this.user)
			loader.hide()
			this.$notify({
				title: this.$t("Delivery Opened Successfully!"),
				type: "success"
			})
		},
    getDeliveryNote() {
      const delivery = this.$store.state.dispatch.delivery
      deliveryNoteService.getDeliveryNote(delivery).then((response) => {
        
        let data = { pdfBase64: response.data, fileName: delivery._source.document.s3.fileName }
        console.log('data: ', data);
        this.downloadEpodPDF(data)
      })
    },
    downloadEpodPDF(data) {
      
      const url = `data:application/pdf;base64,${data.pdfBase64}`;
      const a = document.createElement('a');
      a.download = data.fileName ? data.fileName : `${this.$store.state.dispatch.delivery._source.deliveryNO}.pdf`;
      a.href = url;
      a.target = '_self';

      a.click();
      setTimeout(function () {
        a.remove();
        URL.revokeObjectURL(url);
      }, 100);
    },
    async preloadDelivery() {

      const loader = this.$loading.show()

      try {
        this.$store.commit('dispatch/setIsPreloaded', this.urlDeliveryId)

        const fullorder = (await DeliveryService.getDelivery(this.urlDeliveryId)).data[0]
        console.log("QUERY ORDER ==> ", fullorder)

        const orderTypes = {
          "OUT": "Outbound",
          "IN": "Inbound",
          "IntraCo": "Transfer",
          "TRAD": "Trading"
        }


        const fullorderType = fullorder._source.orderType
        this.$store.commit('dispatch/setOrderType', orderTypes[fullorderType] || fullorderType);
        this.$store.commit('dispatch/setDelivery', fullorder)
        this.$store.commit('dispatch/setIsPreloaded', this.isPreloaded)

        loader.hide()

        this.$notify({
          title: "Delivery loaded.",
          text: `Delivery number ${fullorder._source.deliveryNO} loaded successfully.`,
          type: 'success',
          duration: 3000
        });
      } catch (error) {
        loader.hide()
        this.$notify({
          title: "Error loading delivery.",
          text: `Error loadding delivery with id: ${this.urlDeliveryId}.`,
          type: 'success',
          duration: 3000
        });
      }

      
      return
      if(fullorder._source.logistic.truck.plateNumber != null){
        //const fullTruck = await VehiclesService.getVehicleByPlateNumber(fullorder._source.logistic.truck.plateNumber)
        this.$store.commit('dispatch/setCurrentTruck', fullorder._source.logistic.truck)
      }
    },
    changeTab(e) {
        switch(e.detail) {
            case 'Orders':
              this.$router.push('/orders-deliveries?id=od')
              break;
            case 'Deliveries':
              this.$router.push('/deliveries')
              break;
            case 'Vehicles':
              this.$router.push('/dispatch')
               break;
            case 'Storage Movement':
              this.$router.push('/storage-movement')
               break;
        }
    },
    goToStep(step) {
      switch(step) {
        case 1: // Hide all components
          this.steps.materials = false;
          this.steps.weighbridges = false;
          this.steps.cash = false;
          this.steps.attachments = false;
          this.steps.notes = false;
          this.steps.audit = false;
          this.steps.control=false;
          break;
        case 2: // With delivery loaded shows all components
          this.steps.materials = true;
          this.steps.weighbridges = true;
          this.steps.attachments = true;
          this.steps.notes = true;
          this.steps.audit = true;
          this.steps.control=true;
          break;
        case 3:
          this.steps.weighbridges = true;
          break;
        case 4:
          // console.log ("CASSH!")
          this.steps.cash = true;
          break;
      }
    },
    /*async dispatchAction() {
      console.log('dispatchAction OLD')
      //this.$store.commit('dispatch/setDispatchAction');
    },*/
    async dispatchAction(fromConfirm) {
      console.log('dispatchAction >> ',this.delivery, ':: fromConfirm '+ fromConfirm);
      
      let deliveryChecks = await this.allowDispatch();
      console.log('dispatchAction dispatchAction >> ',deliveryChecks);
      if(deliveryChecks.length == 0 || fromConfirm){
        console.log('dispatch OK')
        this.$store.commit('dispatch/setDispatchAction') 
        this.showDispatchMessage=false;
        this.dispatchMessageData=[];
        this.showDispatchControlMessage=false;
        this.dispatchControlMessageData=[];
        this.treatmentCodeMandatory= true;
      } else{
        console.log('dispatchAction showDispatchMessage')
        let mainChecksMessage = deliveryChecks.filter(((e) => e.type == "MANDATORY"))
        console.log('mainChecksMessage',mainChecksMessage)

        if(mainChecksMessage.length > 0){ //FOUND MAIN CHECKS MESSAGE
          this.showDispatchMessage=true;
          this.dispatchMessageData=mainChecksMessage;
          console.log('this.dispatchMessageData',this.dispatchMessageData)
          this.showDispatchControlMessage=false;
        }else{//NO MAIN CHECKS MESSAGE
          this.showDispatchControlMessage=true;
          this.dispatchControlMessageData=deliveryChecks.filter((item) => item.type != "MANDATORY");
          this.showDispatchMessage=false;
        }
      }
    },
    
    allowDispatch(){

      let deliveryChecks=[];
     
      console.log('allowDispatch orderType ::' ,this.orderType,  ' :: isWaste ::', this.isWaste);

      if(this.isWaste && (this.orderType == this.orderTypeList.INBOUND || this.orderType == this.orderTypeList.TRANSFERIN)){
        console.log('allowDispatch delivery', this.delivery);

        //TREATMENT VALUE MANDATORY
        let treatmentCodeCheckObject = this.treatmentMandatoryCheck();
        if(treatmentCodeCheckObject !='') deliveryChecks.push(treatmentCodeCheckObject)
       
        //CONTROL SECTION MANDATORY
        let controlVisualCheckObject = this.controlMandatoryCheck('VISUAL');
        let controlOlactifCheckObject = this.controlMandatoryCheck('OLACTIF');
        let controlCameraCheckObject = this.controlMandatoryCheck('CAMERA');

        if(controlVisualCheckObject !='') deliveryChecks.push(controlVisualCheckObject)
        if(controlOlactifCheckObject !='') deliveryChecks.push(controlOlactifCheckObject)
        if(controlCameraCheckObject !='') deliveryChecks.push(controlCameraCheckObject)

        //CONTROL SECTION REFUSAL REASON MANDATORY (IF REFUSAL=

        let controlVisualRefusalReasonCheckObject = this.controlMandatoryRefusalReasonCheck('VISUAL');
        let controlOlactifRefusalReasonCheckObject = this.controlMandatoryRefusalReasonCheck('OLACTIF');
        let controlCameraRefusalReasonCheckObject = this.controlMandatoryRefusalReasonCheck('CAMERA');

        if(controlVisualRefusalReasonCheckObject !='') deliveryChecks.push(controlVisualRefusalReasonCheckObject)
        if(controlOlactifRefusalReasonCheckObject !='') deliveryChecks.push(controlOlactifRefusalReasonCheckObject)
        if(controlCameraRefusalReasonCheckObject !='') deliveryChecks.push(controlCameraRefusalReasonCheckObject)


        //CONTROL SECTION WITH REFUSED VALUES
        if (deliveryChecks.length === 0) {
          let controlRefusedCheckObject = this.controlRefusedCheck();
          if(controlRefusedCheckObject !='') deliveryChecks.push(controlRefusedCheckObject)
        }

        return deliveryChecks
      }
      return deliveryChecks
    },
    closeDispatchControlMessage(){
      this.showDispatchControlMessage=false;
      this.dispatchControlMessageData=[];
    },
    continueDispatch(){
      this.dispatchAction(true);
    },
    treatmentMandatoryCheck(){
      const alreadyHaveWasteInfo = this.orderType == this.orderTypeList.INBOUND ?
        (this.delivery._source.material[0].wasteInfo ? true : false) :
        (this.delivery._source.material[0].wasteTransferIn ? true : false)

      let treatmentValue = ''

      console.log('treatmentMandatoryCheck >>>', alreadyHaveWasteInfo)

      if (alreadyHaveWasteInfo) {
        if(this.orderType == this.orderTypeList.INBOUND){
          treatmentValue = this.delivery._source.material[0].wasteInfo.treatmentInfo ?this.delivery._source.material[0].wasteInfo.treatmentInfo.value: '';
        }else{//TRANSFER_IN
          treatmentValue = this.delivery._source.material[0].wasteTransferIn.treatment ?this.delivery._source.material[0].wasteTransferIn.treatment.value: '';
        }
      } else {
        treatmentValue = this.delivery._source.material[0].dap.treatmentInfo ?this.delivery._source.material[0].dap.treatmentInfo.value: '';
      }

      let treatmentCodeCheckObject='';
          
      if(this.treatmentCodeMandatory && (treatmentValue == ''|| treatmentValue == 'null'|| treatmentValue == null)){
        treatmentCodeCheckObject={
          type: 'MANDATORY',
          title: 'Treatment Code message',
          message:'Treatment Code value is mandatory'
        }
      }
      return treatmentCodeCheckObject;
    },

    controlMandatoryCheck(type){
      let controlCheckObject='';
      let controlValue = '';

      const alreadyHaveWasteInfo = this.orderType == this.orderTypeList.INBOUND ?
        (this.delivery._source.material[0].wasteInfo ? true : false) :
        (this.delivery._source.material[0].wasteTransferIn ? true : false)
      
      console.log('controlMandatoryCheck alreadyHaveWasteInfo',alreadyHaveWasteInfo)

      if (alreadyHaveWasteInfo) {
        if(this.orderType == this.orderTypeList.INBOUND){
          if(type == 'VISUAL') controlValue = this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.visual: '';
          if(type == 'OLACTIF') controlValue = this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.olactif: '';
          if(type == 'CAMERA') controlValue= this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.camera: '';
        }else{//TRANSFER_IN
          if(type == 'VISUAL') controlValue = this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.visual: '';
          if(type == 'OLACTIF') controlValue = this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.olactif: '';
          if(type == 'CAMERA') controlValue= this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.camera: '';
        }
        
      }else{
        if(type == 'VISUAL') controlValue = this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.visual: '';
        if(type == 'OLACTIF') controlValue = this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.olactif: '';
        if(type == 'CAMERA') controlValue= this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.camera: '';
        
      }
      if(controlValue == ''|| controlValue == 'null' || controlValue == null){
          controlCheckObject={
            type: 'MANDATORY',
            title: 'Control '  + type + ' message',
            message:'Control ' + type + ' is mandatory'
          }
      }

      return controlCheckObject;
    },
    controlMandatoryRefusalReasonCheck(type){
      let controlRefusalReasonCheckObject='';
      let controlValue = '';
      let controlRefusalReasonValue = '';

      const alreadyHaveWasteInfo = this.orderType == this.orderTypeList.INBOUND ?
        (this.delivery._source.material[0].wasteInfo ? true : false) :
        (this.delivery._source.material[0].wasteTransferIn ? true : false)

      console.log('controlMandatoryRefusalReasonCheck alreadyHaveWasteInfo',alreadyHaveWasteInfo)

      if (alreadyHaveWasteInfo) {
        if(this.orderType == this.orderTypeList.INBOUND){
          if(type == 'VISUAL'){
            controlValue = this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.visual: '';
            controlRefusalReasonValue = this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.visualRefusalReason: '';
          }
          if(type == 'OLACTIF') {
            controlValue = this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.olactif: '';
            controlRefusalReasonValue = this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.olactifRefusalReason: '';
          }
          if(type == 'CAMERA') {
            controlValue= this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.camera: '';
            controlRefusalReasonValue= this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.cameraRefusalReason: '';
          }
        }else{//TRANSFER_IN
          if(type == 'VISUAL'){
            controlValue = this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.visual: '';
            controlRefusalReasonValue = this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.visualRefusalReason: '';
          } 
          if(type == 'OLACTIF') {
            controlValue = this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.olactif: '';
            controlRefusalReasonValue = this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.olactifRefusalReason: '';
          }
          if(type == 'CAMERA') {
            controlValue= this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.camera: '';
            controlRefusalReasonValue= this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.cameraRefusalReason: '';
          }
        }
        
      }else{
        if(type == 'VISUAL') {
          controlValue = this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.visual: '';
          controlRefusalReasonValue = this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.visualRefusalReason: '';
        }
        if(type == 'OLACTIF') {
          controlValue = this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.olactif: '';
          controlRefusalReasonValue = this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.olactifRefusalReason: '';
        } 
        if(type == 'CAMERA') {
          controlValue= this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.camera: '';
          controlRefusalReasonValue= this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.cameraRefusalReason: '';
        } 
        
      }
      if(controlValue == 'REFUSED' && (controlRefusalReasonValue == ''|| controlRefusalReasonValue == 'null' || controlRefusalReasonValue == null)){
          controlRefusalReasonCheckObject={
            type: 'MANDATORY',
            title: 'Control Refusal Reason '  + type + ' message',
            message:'Control Refusal Reason ' + type + ' is mandatory'
          }
      }

      return controlRefusalReasonCheckObject;


    },
    controlRefusedCheck() {
      let controlRefusedCheckObject='';

      const alreadyHaveWasteInfo = this.orderType == this.orderTypeList.INBOUND ?
        (this.delivery._source.material[0].wasteInfo ? true : false) :
        (this.delivery._source.material[0].wasteTransferIn ? true : false);

      console.log('controlRefusedCheck alreadyHaveWasteInfo',alreadyHaveWasteInfo)
      

      let controlVisualValue = ''
      let controlOlactifValue = ''
      let controlCameraValue = ''

      if (alreadyHaveWasteInfo) {

        if(this.orderType == this.orderTypeList.INBOUND){
          console.log('controlRefusedCheck alreadyHaveWasteInfo dap control',this.delivery._source.material[0].wasteInfo.control)
          controlVisualValue = this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.visual: '';
          controlOlactifValue =  this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.olactif: '';
          controlCameraValue =  this.delivery._source.material[0].wasteInfo.control ?this.delivery._source.material[0].wasteInfo.control.camera: '';
        }else{//TRANSFER_IN
          console.log('controlRefusedCheck alreadyHaveWasteInfo dap control',this.delivery._source.material[0].wasteTransferIn.control)
          controlVisualValue = this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.visual: '';
          controlOlactifValue =  this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.olactif: '';
          controlCameraValue =  this.delivery._source.material[0].wasteTransferIn.control ?this.delivery._source.material[0].wasteTransferIn.control.camera: '';
        }
        
      } else {
        console.log('controlRefusedCheck alreadyHaveWasteInfo dap ',this.delivery._source.material[0].dap)
        console.log('controlRefusedCheck alreadyHaveWasteInfo dap control',this.delivery._source.material[0].dap.control)
        controlVisualValue = this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.visual: '';
        controlOlactifValue =  this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.olactif: '';
        controlCameraValue =  this.delivery._source.material[0].dap.control ?this.delivery._source.material[0].dap.control.camera: '';
      }
          console.log('controlVisualValue',controlVisualValue)
           console.log('controlOlactifValue',controlOlactifValue)
             console.log('controlCameraValue',controlCameraValue)
      if(controlVisualValue.toUpperCase()=='REFUSED' || controlOlactifValue.toUpperCase()=='REFUSED' ||controlCameraValue.toUpperCase()=='REFUSED'){
        controlRefusedCheckObject={
            type: 'CONTROL',
            title: 'Control refused title',
            message:'Control refused message1',
            message1:'Control refused message2',
            message2:'Control refused question'
          }
      }
      return controlRefusedCheckObject;
    },

    downloadDAP_Blank(){
      let bucket_name = app.config.globalProperties.$config.aws.AWS_S3_BUCKET_NAME
      console.log("downloadDAP_Blank AWS Bucket data >>> ",bucket_name);
      let code_country= JSON.parse(sessionStorage.getItem('user')).country.code
      let data ={
        fileName: code_country + "/documents/DAP_VF13.pdf",
        bucket_name: bucket_name
      }
      WasteService.getWasteBlankPDF(data).then((res) => {
        let dataPDF = { file: res.data, fileName: data.fileName }
        //console.log("downloadDAP >>>",dataPDF)
        this.downloadPDF(dataPDF);
        /*this.$notify({
          title: this.$t("DAP Download"),
          text: this.$t(`Downloaded correctly`),
          type: 'success',
          duration: 3000
        });*/
        }).catch((error) => {
          console.log('ERROR getWasteBlankPDF -->', error);
          /*this.$notify({
            title: this.$t("DAP Download"),
            text: this.$t(`Error downloading PDF`,),
            type: 'error',
            duration: 3000
          });*/        
      });
    },
    downloadPDF(data) {
      const url = `data:application/pdf;base64,${data.file}`;
      const a = document.createElement('a');
      a.download = data.fileName ? data.fileName : `${this.currentDelivery._source.deliveryNO}.pdf`;
      a.href = url;
      a.target = '_self';
      a.click();
      setTimeout(function () {
        a.remove();
        URL.revokeObjectURL(url);
      }, 100);
    },
  },
  created() {
    if (!this.$store.hasModule('dispatch')) {
      this.$store.registerModule('dispatch', require('@/store/dispatch').default);
    }

    if (this.isPreloaded) {
      this.preloadDelivery()
    }

  },
  beforeUnmount() {
    if (this.$store.hasModule('dispatch')) {
      this.$store.unregisterModule('dispatch');
    }
  },
}
</script>

<style lang="scss">
.app-content {
.soldToForm {
  width: 900px;
}

&.new-order {
  background-color: #F5F5F5;

  .modal-selected-contract {
    padding: rem(16px) rem(16px);
  }

  .seeker-item--contract {
    .seeker-item {
      &__name {
        display: flex;
        justify-content: space-between;
        font-size: rem(16px);
        font-weight: bold;

        span {
          font-size: rem(14px);
          font-weight: normal;
        }
      }

      &__desc {
        color: rgba(black, .5);
      }
    }
  }
}
}

.data-list__filters {
  &.row-sticky {
      position: sticky;
      top: 0;
      z-index: 2;
      width: 100%;
      padding-right: 4.375rem;
      padding-left: 1.875rem;
      display: inline-block;
      background-color: #FFF;
  }

  &-tabs {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.7rem;
  }

  &-btns {
      width: 15%;
  }

  &-search {
      display: flex;
      gap: 0.5rem;
  }
}

lh-button-tabs.data-list__filters-btns {
  &.buttons {
      width: 25%;
      cursor: pointer;
      background-color: white;
  }

  &.selector {
      width: 100%;
      cursor: pointer;
      background-color: white;
  }
}

.app-content__content {
  &.truck {
      background-color: #F5F5F5;
      padding-top: 0;

      .tabButton {
        display: flex;
        padding: 2rem 1.875rem 0;
      }
        
      .new-order__step {
          margin-top: 1rem;
      }
  }
}

.p-checkbox-box {
margin-top: 7px;
}

.tooltip-checkboox {
display: flex;
align-items: center;
gap: 0.5rem;
}

.checkboxLabel {
margin-top: 15px;
margin-right: 5px;
}

.new-order__step {
+.new-order__step {
  margin-top: rem(24px);
}
}

textarea.notes-note {
height: rem(109px);
}

.modal-delivery-plant {
display: flex;
align-items: center;
}

.vs__search {
height: 3rem;
border: 1px solid black;
padding-left: 2rem;
outline: none;
width: 100%;
display: flex;
}

.vs__actions {
display: none;
align-items: center;
padding: 4px 6px 0 3px;
}

.vs__dropdown-menu {
position: absolute;
overflow: auto;
z-index: 999;
width: 100%;
border: 1px solid;
margin-top: 0.25rem;
background-color: white;
padding-left: 0px;
max-height: 250px;
}

.vs__dropdown-option {
display: flex;
justify-content: space-between;
font-size: rem(16px);
font-weight: bold;
border-top: 1px solid;
}

.seeker-item {
padding: 0.625rem 1rem;

&__name {
  display: flex;
  justify-content: space-between;
  font-size: rem(16px);
  font-weight: bold;

  span {
    font-size: rem(14px);
    font-weight: normal;
  }
}

&__desc {
  color: rgba(black, .5);
}
}

.modal-confirm {

padding-top: rem(32px);
padding-bottom: rem(32px);

h2 {
  font-size: rem(18px);
}

.order-type {

  +.order-type {
    margin-top: rem(32px);
  }

  h2 {
    background-color: #EEE;
    font-size: rem(16px);
    line-height: rem(24px);
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-amount {
      *+* {
        margin-left: rem(16px);
      }
    }
  }
}

.notes {
  margin-top: rem(32px);

  h2 {
    margin-bottom: rem(18px);
  }
}

}

.vs__selected {
display: none;
}

.cl-result {
&__tag {
  color: #FF2000;
}
}

.pointer{
cursor: pointer;
}

.alert_past_date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .alert_past_date_actions {
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>


