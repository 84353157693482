<template>
    <!-- PRIMER PASO -->
    <Step v-if="!showData" :disableButtons="disableButtons">
        <div class="columns">
            <div class="column">
                <div class="label__input">
                    <div class="form-control__label">{{ $t('Vehicle') }}</div>
                </div>
                <!--div class="control__input">
                    <InputText v-if="currentTruck !== null" :style="{ width: '340px', height: '50px' }"
                        :disabled="currentTruck === null ? 'false' : 'true'" :placeholder="$t('Vehicle')"
                        :value="currentTruck === null ? 'Vehicle' : currentTruck.licensePlateNumber" />
                    <div class="seeker vehicle" v-if="currentTruck === null">
                        <v-select select :placeholder="$t('Search Vehicle')" item-text="number" item-value="number"
                            label="licensePlateNumber" class="seeker__wrapper" v-model="currentTruck" :options="trucks"
                            :clearable="false" @search="searchTruck" @update:modelValue="setTruck"
                            :style="{ 'width': '340px', 'font-size': '14px' }">
                            <template #no-options="{ search, searching, loading }">
                                {{ $t('Type at least 2 characters') }}
                            </template>
                            <template #option="option" @selectedOption="setTruck">
                                <div class="seeker-item">
                                    <div class="seeker-item__name">{{ option.licensePlateNumber }}</div>
                                    <div class="seeker-item__desc">{{ option.details.category.vehicleType }} {{
                                        option.carrierName }}</div>
                                </div>
                            </template>
                        </v-select>

                    </div>
                    <i v-if="currentTruck !== null"
                        :style="{ 'margin-top': '16px', 'margin-left': '20px', 'cursor': 'pointer' }"
                        class="zmdi zmdi-edit" @click="editTruck"></i>
                    <Button v-if="currentTruck === null" class="p-button btn btn--secondary"
                        icon="zmdi zmdi-assignment zmdi-hc-lg" :style="{ 'margin-left': '5px', 'border': 'none' }"
                        @click="showModalTruck" />
                </div-->
                <div class="control__input" v-if="currentTruck === null" style="margin-top: 1rem;">
                    <InputText :placeholder="'Unregistered Vehicle'" v-model="unregisteredVehicle"></InputText>
                    <Button v-if="currentTruck === null" class="p-button btn btn--secondary"
                        icon="zmdi zmdi-check zmdi-hc-lg" :style="{ 'margin-left': '5px', 'border': 'none' }"
                        @click="saveUnregisteredVehicle" />
                </div>
            </div>
            <div class="column">
                <div class="label__input" v-if="needTrailer">
                    <div class="form-control__label">{{ $t('Trailer') }}</div>
                </div>
                <div class="control__input" v-if="needTrailer">
                    <v-select select :placeholder="$t('Search Trailer')" item-text="number" item-value="number"
                        :label="$t('licensePlateNumber')" class="seeker__wrapper" v-model="currentTrailer"
                        :options="trailers.filter(x => x.carrierCode == currentTruck.carrierCode)" :clearable="false"
                        @search="searchTrailers" @update:modelValue="setTrailer"
                        :style="{ 'width': '340px', 'font-size': '14px', 'display': 'inline-block' }">
                        <template #no-options="{ search, searching, loading }">
                            {{ $t('Type at least 2 characters') }}
                        </template>
                        <template #option="option" @selectedOption="setTrailer">
                            <div class="seeker-item">
                                <div class="seeker-item__name">{{ option.vehicleId }}</div>
                                <div class="seeker-item__desc">{{ option.licensePlateNumber }}</div>
                            </div>
                        </template>
                    </v-select>
                    <i v-if="currentTrailer !== null"
                        :style="{ 'margin-top': '16px', 'margin-left': '20px', 'cursor': 'pointer' }"
                        class="zmdi zmdi-edit" @click="editTrailer"></i>
                    <Button v-if="currentTrailer === null" class="p-button btn btn--secondary"
                        icon="zmdi zmdi-assignment zmdi-hc-lg" :style="{ 'margin-left': '5px', 'border': 'none' }"
                        @click="showModalTrailer" />
                </div>
            </div>
            <div class="column"></div>
            <div class="column"></div>
        </div>

    </Step>

    <Dialog v-model:visible="vehiclesModal" :header="$t('Vehicles')" :dismissable-mask="true" :draggable="false"
        closable style="width: 75rem;">
        <VehicleList @checkVehicle="setTruckFromModal" :delivery="delivery" class="m-5" />
    </Dialog>

    <Dialog v-model:visible="trailersModal" :header="$t('Trailers')" :dismissable-mask="true" :draggable="false"
        closable style="width: 75rem;">
        <TrailerList @checkTrailer="setTrailerFromModal" :carrier="currentTruck != null ? currentTruck.carrierName : ''"
            class="m-5" />
    </Dialog>

    <!-- SEGUNDO PASO -->
    <Step v-if="showData" :disableButtons="disableButtons" @resetDispatch="resetValues">
        <div class="columns">
            <div class="column">
                <FormControl :label="$t('Vehicle')">

                    <div class="columns">
                        <div class="column">{{ currentTruck.licensePlateNumber }}</div>
                        <div class="column pointer"><i class="zmdi zmdi-edit" @click="editTruck"></i></div>
                    </div>
                </FormControl>
            </div>
            <div class="column">
                <FormControl :label="$t('Trailer')">
                    <div class="columns">
                        <div class="column">{{ currentTrailer !== null ? currentTrailer.licensePlateNumber : '' }}</div>
                        <div v-if="needTrailer" class="column pointer"><i class="zmdi zmdi-edit"
                                @click="editTrailer"></i>
                        </div>
                    </div>
                </FormControl>
            </div>
            <div class="column">
                <FormControl :label="$t('Vehicle Type')">{{ currentTrailer == null ?
                    currentTruck.details.category.vehicleType : currentTrailer.details.category.vehicleType }}
                </FormControl>
            </div>
            <div class="column">
                <FormControl :label="$t('MWA')" v-if="!needTrailer">
                    {{ currentTruck.mwa != undefined ? currentTruck.mwa.toLocaleString(numberMask) + ' ' +
                    currentTruck.unit
                    : '' }}
                </FormControl>
                <FormControl :label="$t('MWA')" v-if="needTrailer">
                    {{ currentTrailer.mwa != undefined ? currentTrailer.mwa.toLocaleString(numberMask) + ' ' +
                    currentTrailer.unit : '' }}
                </FormControl>
            </div>
            <!--div class="column">
                <FormControl :label="$t('Owner')"> {{ currentTruck.carrierName ? currentTruck.carrierName : '' }}
                </FormControl>
            </div-->
            <!--div class="column" v-if="currentTruck != null && currentFWAgent != null">
                <FormControl :label="$t('Forwarding agent')" :style="{ 'position': 'relative' }">{{ currentFWAgent ?
                    currentFWAgent.general.name : '' }}
                </FormControl>
            </div-->
            <!--div class="column" v-else>
                <div class="label__input">
                    <div class="form-control__label">{{ $t('Forwarding agent') }}</div>
                </div>
                <InputText :style="{ width: '110px', height: '50px' }" disabled="true" :placeholder="$t('FW Agent')"
                    :value="currentFWAgent === null ? '' : currentFWAgent.vendor" />
                <Button class="p-button btn btn--secondary" icon="zmdi zmdi-assignment zmdi-hc-lg"
                    :style="{ 'margin-left': '5px', 'border': 'none' }" @click="showModalFWAgent" />
            </div-->
            <div class="column"
                v-if="$store.state.dispatch.delivery !== null && $store.state.dispatch.delivery._source.externalId">
                <FormControl :label="$t('External Id')"> {{ $store.state.dispatch.delivery._source.externalId }}
                </FormControl>
            </div>
            <div class="column" v-else></div>
            <div class="column"></div>
        </div>

        <div class="columns weight">
            <div class="column">
                <FormControl :label="$t('Tare weight')">
                    {{ currentFirstWeight ? transformToUnit(currentFirstWeight.weight, entryExitWeightUnit,
                        shownWeighingUOM) + ' TNE' : '' }}
                </FormControl>
            </div>

            <!--div class="column">
                <FormControl :label="$t('Theorical net weight')" v-if="!needTrailer"> {{
                    currentTruck.technicalInfo.tareWeight + ' TNE' }} </FormControl>
                <FormControl :label="$t('Theorical net weight')" v-if="needTrailer"> {{
                    currentTrailer.technicalInfo.tareWeight + ' TNE' }} </FormControl>
            </div-->

            <div class="column">
                <FormControl :label="$t('Date')">
                    {{ dateFirstWeight != null ? moment(dateFirstWeight).format(displayMomentFormat) : '' }}
                </FormControl>
            </div>

            <div class="column">
                <FormControl :label="$t('Time')">
                    {{ dateFirstWeight != null ? moment(dateFirstWeight).tz(userTimezone).format("HH:mm:ss") : '' }}
                </FormControl>
            </div>

            <!--div class="column">
                <div v-if="(expirationDateMessage && currentFirstWeight !== null)" class="expirationDateContainer">
                    <Badge class="weighModal" size="large" :severity="expirationDateMessageStatus"
                        :value="expirationDateMessage" />
                </div>
            </div-->

            <!--div class="column">
                <FormControl :label="$t('Weighbridge')"> {{ currentFirstWeight ? currentFirstWeight.weightBridge : '' }}
                </FormControl>
            </div-->
            <!--div class="column">
                <FormControl :label="$t('Username')">
                    {{ currentFirstWeight ? currentFirstWeight.user.userName : ''}}
                </FormControl>
                <FormControl :label="$t('Weigh number')"> {{ currentFirstWeight ? currentFirstWeight.weight_number : ''
                    }}
                </FormControl>
            </div-->
            <div class="column">
                <FormControl :label="$t('Reference')" >
                    <InputText v-model="reference" @input="handleReferenceChange" />
                </FormControl>
            </div>
            <div class="column"></div>

        </div>

        <AssignmentsTable v-if="checkAssignmentsShow" :countAssignments="countAssignments" :exwork="exwork"
            @confirmActionNewOrder="confirmActionNewOrder" @showModalAssignmets="showModalAssignmets"
            @entryPlant="entryPlant" />

        <AssignmentDeliveryData v-else-if="!$store.state.dispatch.isPreloaded" />

    </Step>

    <Dialog class="fwaVehicles" :header="'FW Agents'" v-model:visible="fwaModal" :dismissable-mask="true"
        position="center" :style="{ 'width': '60rem' }">
        <FWAgentList @checkFWAgent="assignFWAgent($event)" />
    </Dialog>

    <Dialog class="modalAssigments" :header="assignmentTitle" v-model:visible="loadAssignments" :dismissable-mask="true"
        closable :style="{ 'width': '110rem' }" @hide="closeAssignmentModal">
        <AssignmentListModal :assignmentType="assignmentType" :assignmentData="assignmentData"
            @dispatch="AssignmentListdispatchAction" @clone="cloneOrder" />
    </Dialog>

    <Dialog :header="'Assignments linked'" v-model:visible="alertModal" :dismissable-mask="true"
        :style="{ 'width': '40rem' }">
        <div class="alertModal">
            <p>There are assignments with higher priority to the select one, are you sure you want to continue?</p>
            <div class="alertButtons">
                <lh-button size="medium" @click="closeModalAlert(1)">{{ $t('NO') }}</lh-button>
                <lh-button size="medium" type="primary" @click="confirmAction">{{ $t('YES') }}</lh-button>
            </div>
        </div>
    </Dialog>

    <Dialog :header="'Alert'" v-model:visible="alertModalTare" modal :dismissable-mask="true"
        :style="{ 'width': '40rem' }">
        <div class="alertModal">
            <p>This vehicle needs to be tared before continue.</p>
            <div class="alertButtons">
                <lh-button size="medium" type="primary" @click="closeModalAlert(2)">{{ $t('OK') }}</lh-button>
            </div>
        </div>
    </Dialog>

</template>

<script setup>

import Step from "@/components/dispatch/utils/step.vue";
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import AssignmentsTable from '@/components/dispatch/utils/assignmentsTable';
import AssignmentListModal from '@/components/dispatch/utils/AssignmentListModal';
import AssignmentDeliveryData from "@/components/dispatch/utils/AssignmentDeliveryData.vue";
import FormControl from "@/components/common/FormControl.vue";
import FWAgentList from '@/components/Deliveries/data-table/FWAgentListModal';
import TrailerList from '@/components/Deliveries/data-table/TrailerListModal';
import VehicleList from '@/components/Deliveries/data-table/VehicleListExpressModal';

import DeliveryService from "@/services/api/delivery.js";
import OrderService from '@/services/api/order.js';
import DriverService from "@/services/api/drivers.js";
import VehicleService from "@/services/api/vehicles.js";
import VendorService from "@/services/api/vendors.js";
import TransitService from '@/services/api/transit.js';
import DeliveryNoteService from "@/services/api/deliveryNote.js";
import DataSyncService from "@/services/api/dataSync.js"

import moment from 'moment';
import commonClasses from "@/store/commonClasses.js";
import * as Validation from '@/helper/validateWeights';
import * as FileHelper from '@/helper/files';
import { transformToUnit } from '@/helper/weightUnit';

</script>


<script>
export default {
    name: "VehicleAssignations",
    components: {
        Step,
        Badge,
        Button,
        InputText,
        Dialog,
        AssignmentsTable,
        AssignmentListModal,
        FormControl,
        FWAgentList,
        TrailerList,
        VehicleList,
    },
    data: function () {
        return {
            reference:null,
            delivery: { _source: { deliveryType: 'Delivered' } },
            plant: null,
            user: null,
            currentTruck: null,
            currentTrailer: null,
            currentFirstWeight: null,
            currentFWAgent: null,
            currentDriver: null,
            needTrailer: false,
            trucks: [],
            trailers: [],
            vehiclesModal: false,
            trailersModal: false,
            fwaModal: false,
            showData: false,
            displayMomentFormat: JSON.parse(localStorage.getItem("dateFormat")) ? JSON.parse(localStorage.getItem("dateFormat")).moment : 'DD/MM/YYYY',
            weighingExpirationRange: JSON.parse(localStorage.getItem("userPlant")).plant_parameters.find(p => p.code == 'latest_weight_days').value,
            country: JSON.parse(sessionStorage.getItem("user")).country.code,
            countAssignments: {
                countVehicleDeliveries: null,
                countTruckDeliveries: null,
                countOwnerDeliveries: null,
                countFWADeliveries: null,
                countUnplannedDeliveries: null
            },
            dateFirstWeight: null,
            expirationDateMessage: null,
            expirationDateMessageStatus: null,
            entryExitWeightUnit: 'KGM',
            shownWeighingUOM: 'TNE',
            loadAssignments: false,
            assignmentType: '',
            assignmentData: null,
            exwork: false,
            dispatchDelivery: null,
            deliveryModal: false,
            alertModal: false,
            userTimezone: null,
            disableButtons: false,
            entity: "delivery",
            alertModalTare: false,
            deliveryLoaded: false,
            orderTypeList: {
                OUTBOUND: 'Outbound',
                INBOUND: 'Inbound',
                TRANSFERIN: 'Transfer In',
                TRANSFEROUT: 'Transfer Out'
            },
            unregisteredVehicle: null
        }
    },
    created() {
        this.plant = JSON.parse(sessionStorage.getItem("user")).plant;
        this.user = JSON.parse(sessionStorage.getItem("user"));
        this.userTimezone = this.user.country.timezone
        this.$store.commit('dispatch/resetValues')
    },
    async mounted() {
        if (this.toWaste != null) {
            if (this.toWaste.value) {
                this.$store.commit('dispatch/setOrderType', this.orderTypeList.INBOUND)
                this.$store.commit('dispatch/setIsWaste', true)
            } else {
                this.$store.commit('dispatch/setOrderType', this.orderTypeList.OUTBOUND)
                this.$store.commit('dispatch/setIsWaste', false)
            }
            let vehicleToLoad = await VehicleService.findVehicles(
                {
                    filters: {
                        fleetCategory: ['PM207032', 'PM207028'],
                        country: this.user.country.code
                    },
                    search: this.toWaste.vehicleId
                })
            await this.setTruckFromModal(vehicleToLoad.data[0])
            if (this.toWaste.trailerId != "null") {
                let trailerToLoad = await VehicleService.findVehicles(
                    { filters: { fleetCategory: ['PM207029'] }, search: this.toWaste.trailerId }
                )
                await this.setTrailerFromModal(trailerToLoad.data[0])
            }
        }
    },
    methods: {
        handleReferenceChange(){
            this.$store.commit('dispatch/setReference', this.reference)
        },
        async saveUnregisteredVehicle() {
            const vehicle = {
                "hits": 233,
                "country": "FR",
                "cluster": "BORDEAUX",
                "licensePlateNumber": this.unregisteredVehicle,
                "numberOfAxels": "3",
                "maximumLoadWeight": "26",
                "vehicleId": this.unregisteredVehicle,
                "fleetCategory": "0",
                "unitWeight": "TNE",
                "companyCode": "0",
                "active": 1,
                "carrierCode": "0",
                "carrierName": "0",
                "createdforpickup": 0,
                "haulierType": null,
                "driverName": null,
                "driverAutoId": null,
                "general": {
                    "_custom": {
                        "type": "reactive",
                        "stateTypeName": "Reactive",
                        "value": {
                            "equipmentCategory": "0",
                            "description": "0",
                            "status": true,
                            "lastTare": "0",
                            "lastTareDate": "0"
                        }
                    }
                },
                "details": {
                    "_custom": {
                        "type": "reactive",
                        "stateTypeName": "Reactive",
                        "value": {
                            "carrierName": "0",
                            "availability": {
                                "basePlantCode": "0",
                                "basePlantName": "0",
                                "basePlantShift": {
                                    "shiftname": null,
                                    "startHour": "Invalid date",
                                    "startDate": "Invalid date",
                                    "startTime": "Invalid date",
                                    "endHour": "Invalid date",
                                    "endDate": "Invalid date",
                                    "endTime": "Invalid date",
                                    "shiftColor": null
                                }
                            },
                            "category": {
                                "vehicleType": "0"
                            },
                            "organization": {
                                "resource_categoryCode": null,
                                "resource_categoryName": null
                            },
                            "characteristics": {
                                "chapter8": "0",
                                "chutedVehicle": "0",
                                "insulated": "0",
                                "crossRail": "0",
                                "euroStandard": "0",
                                "londonEmissionZone": "0",
                                "steelBody": "0"
                            }
                        }
                    }
                },
                "technicalInfo": {
                    "_custom": {
                        "type": "reactive",
                        "stateTypeName": "Reactive",
                        "value": {
                            "capacity": 0,
                            "tareWeight": "0",
                            "maximumLoadWeight": "0"
                        }
                    }
                },
                "characteristics": {
                    "_custom": {
                        "type": "reactive",
                        "stateTypeName": "Reactive",
                        "value": {
                            "chapter8": "0",
                            "chutedVehicle": "0",
                            "insulated": "0",
                            "crossRail": "0",
                            "euroStandard": "EU6",
                            "londonEmissionZone": "false",
                            "steelBody": "0"
                        }
                    }
                },
                "localVehicle": 0,
                "tare": "7",
                "mwa": "26",
                "unit": "0"
            }
            this.currentTruck = vehicle
            this.$store.commit('dispatch/setCurrentTruck', vehicle)
            await this.setTruck(vehicle)
        },
        showModalTruck() { this.vehiclesModal = true },
        showModalTrailer() { this.trailersModal = true },
        searchTruck(e) {
            let country = this.user.country.code
            let searchTerm = e;
            if (searchTerm != null && searchTerm.length >= 2) {
                let filters = {
                    filters: { fleetCategory: ['PM207032', 'PM207028'], country },
                    search: searchTerm
                }
                VehicleService.findVehicles(filters).then(response => {
                    if (response.data.length > 0) {
                        this.trucks = response.data
                    }
                }).catch(err => { console.error('ERROR FINDING VEHICLES -->', err) })

                VehicleService.getVehicleByPlate(searchTerm).then(response => {
                    if (response.data.length > 0) {
                        response.data.forEach((e) => {
                            e._source.vehicleId = e._id;
                            e._source.licensePlateNumber = e._source.licensePlate;
                            this.trucks.push(e._source)
                        });
                    }
                }).catch(err => { console.error('ERROR GETTING VEHICLE BY PLATE -->', err) });
            }
        },
        searchTrailers(e) {
            let searchTerm = e;
            if (searchTerm != null && searchTerm.length >= 2) {
                let filters = { filters: { fleetCategory: ['PM207029'] }, search: searchTerm }
                VehicleService.findVehicles(filters).then(response => {
                    console.log("SELECTED TRUCK", this.currentTruck, "Trailers", response.data)
                    this.trailers = response.data
                }).catch(err => { console.error('ERROR SEARCHING TRAILERS -->', err) })
            }
        },
        async setTruckFromModal(truck) {
            this.currentTruck = truck
            this.vehiclesModal = false;
            await this.setTruck(truck)
        },
        async setTrailerFromModal(trailer) {
            this.currentTrailer = trailer
            this.trailersModal = false;
            await this.setTrailer(trailer)
        },
        async setTruck(truck) {
            // Need Trailer?
            if (truck.fleetCategory === 'PM207028') { this.needTrailer = true }
            else { await this.assignVehicle() }
        },
        async setTrailer(trailer) {
            this.currentTrailer = trailer
            await this.assignVehicle()
        },
        async backToSelectTrailer() {
            try {
                let currentTruck = this.currentTruck;
                //this.resetValues();
                this.currentTruck = currentTruck;
                console.log('CURRENT TRUCK FLEET CATEGORY -->', this.currentTruck.fleetCategory)

                if (this.currentTruck.fleetCategory === 'PM207028') { this.needTrailer = true }

                let response = await VehicleService.getVehicle(this.currentTruck.vehicleId)
                let currentTruckSelected = response.data;
                let { technicalInfo } = currentTruckSelected

                this.currentTruck = {
                    ...this.currentTruck,
                    tare: technicalInfo.weight != '' && technicalInfo.weight != undefined ? technicalInfo.weight : currentTruckSelected.tareWeight,
                    mwa: technicalInfo.maximumLoadWeight != '' && technicalInfo.maximumLoadWeight != undefined ? technicalInfo.maximumLoadWeight : currentTruckSelected.mwa,
                    unit: technicalInfo.unitWeight != undefined && technicalInfo.unitWeight != '' ? technicalInfo.unitWeight : "No Unit"
                }

                if (currentTruckSelected.carrierId && this.currentFWAgent === null) {
                    await this.getVendorInfo(currentTruckSelected.carrierId)
                }
                if (currentTruckSelected.driverId && currentTruckSelected.driverId != null) {
                    await this.getDriverInfo(currentTruckSelected.driverId)
                }

                this.$store.commit('dispatch/setCurrentTruck', this.currentTruck)
            } catch (err) { console.error(err) }

            await this.getVehicleType();
        },
        async assignVehicle() {
            let loader = this.$loading.show();
            // Load truck data
            try {

                let response = await VehicleService.getVehicle(this.currentTruck.vehicleId)

                let currentTruckSelected = response.data;
                let { technicalInfo } = currentTruckSelected

                this.currentTruck = {
                    ...this.currentTruck,
                    tare: technicalInfo.weight != '' && technicalInfo.weight != undefined ? technicalInfo.weight : currentTruckSelected.tareWeight,
                    mwa: technicalInfo.maximumLoadWeight != '' && technicalInfo.maximumLoadWeight != undefined ? technicalInfo.maximumLoadWeight : currentTruckSelected.mwa,
                    unit: technicalInfo.unitWeight != undefined && technicalInfo.unitWeight != '' ? technicalInfo.unitWeight : "No Unit"
                }

                if (currentTruckSelected.carrierId && this.currentFWAgent === null) {
                    await this.getVendorInfo(currentTruckSelected.carrierId)
                }
                if (currentTruckSelected.driverId && currentTruckSelected.driverId != null) {
                    await this.getDriverInfo(currentTruckSelected.driverId)
                }

                this.$store.commit('dispatch/setCurrentTruck', this.currentTruck)
            } catch (err) { console.error(err) }

            // load trailer data if needed
            if (this.needTrailer) {
                try {
                    let response = await VehicleService.getVehicle(this.currentTrailer.vehicleId)
                    let currentTrailerSelected = response.data;
                    let { technicalInfo } = currentTrailerSelected;

                    this.currentTrailer = {
                        ...this.currentTrailer,
                        tare: technicalInfo.weight != '' && technicalInfo.weight != undefined ? technicalInfo.weight : currentTrailerSelected.tareWeight,
                        mwa: technicalInfo.maximumLoadWeight != '' && technicalInfo.maximumLoadWeight != undefined ? technicalInfo.maximumLoadWeight : currentTrailerSelected.mwa,
                        unit: technicalInfo.unitWeight != undefined && technicalInfo.unitWeight != '' ? technicalInfo.unitWeight : "No Unit"
                    }

                    // calculate Tare and MWA
                    let result = Validation.validateMWA(this.currentTruck, this.currentTrailer)
                    this.currentTrailer.technicalInfo.tareWeight = result.tare
                    this.currentTrailer.technicalInfo.maximumLoadWeight = result.mwa

                    this.$store.commit('dispatch/setCurrentTrailer', this.currentTrailer)
                } catch (err) { console.error(err) }
            }

            // Check if delivery today loading/shipping for this truck and load if exists
            try {
                let id = null

                let deliveryLoading = await VehicleService.assignedLoadingDelivery({
                    orderType: this.getOrderType(this.orderType),
                    truckId: this.currentTruck.vehicleId,
                    trailerId: this.needTrailer ? this.currentTrailer.vehicleId : null,
                    transferType: this.orderType === this.orderTypeList.TRANSFEROUT ? 'OUT' : null
                })

                if (deliveryLoading.data.length > 0) {
                    id = deliveryLoading.data[0]._source.deliveryNO
                    if (this.orderType == this.orderTypeList.OUTBOUND || this.orderType == this.orderTypeList.TRANSFEROUT) {
                        this.AssignmentListdispatchAction(deliveryLoading.data[0], 'dispatch')
                    } else if (this.orderType == this.orderTypeList.INBOUND || this.orderType == this.orderTypeList.TRANSFERIN) {
                        this.$store.commit('dispatch/setDelivery', deliveryLoading.data[0])
                        this.disableButtons = true
                    }
                    this.deliveryLoaded = true
                    this.$notify({
                        title: this.$t('Delivery found'),
                        text: `${this.$t('Delivery')} ${id} ${this.$t('loaded')}`,
                        type: 'success',
                        duration: 5000
                    });
                }

            } catch (err) { console.error(err) }

            // Load first weight data
            if (this.currentFirstWeight.weight === null) {
                DeliveryService.getLatestTruckWeighing(this.currentTruck.vehicleId, this.plant.id, this.currentTrailer ? this.currentTrailer.vehicleId : null).then((response) => {
                    if (response.data.weight) {
                        this.currentFirstWeight = {
                            date: response.data.date,
                            weight: response.data.weight,
                            weightBridge: response.data.weightbridge,
                            weight_number: response.data.weight_number,
                            user: {
                                userID: "",
                                userName: response.data.user
                            }
                        };
                        this.dateFirstWeight = this.currentFirstWeight.date
                        this.checkWeighingExpiration(this.currentFirstWeight.date, this.weighingExpirationRange);
                        this.$store.commit("dispatch/setTare", transformToUnit(this.currentFirstWeight.weight, 'KGM', this.shownWeighingUOM));
                        // Only for weightbridge OUT (Outbound && Transfer Out)
                        if (this.orderType == this.orderTypeList.OUTBOUND || this.orderType == this.orderTypeList.TRANSFEROUT)
                            this.$store.commit("dispatch/setEntryWeight", this.currentFirstWeight)

                        this.$notify({ title: this.$t('First Weight Loaded'), type: 'warning' })
                    } else {
                        this.currentFirstWeight = {
                            date: null,
                            weight: 0,
                            weightBridge: "",
                            weight_number: "",
                            user: { userName: "", userID: "" }
                        };
                        this.expirationDateMessage = this.$t('Need to Tare now')
                        this.expirationDateMessageStatus = 'danger'
                    }
                })
            }

            if (this.isPreloaded) {
                this.showData = true
                loader.hide()
                return
            }

            await this.getVehicleType();

            // show data table
            if (this.orderType == this.orderTypeList.OUTBOUND && !this.deliveryLoaded) this.$store.commit('dispatch/setDelivery', null)
            this.showData = true

            // Count deliveries
            this.countDeliveries()
            loader.hide()

        },
        async countDeliveries() {
            try {
                if (this.currentTruck.carrierId != '88888888') {
                    let data = {
                        truckId: this.currentTruck.vehicleId,
                        trailerId: this.currentTrailer !== null && this.needTrailer ? this.currentTrailer.vehicleId : '',
                        owner: this.currentTruck.carrierCode,
                        fwa: this.currentFWAgent.vendor,
                        plantCode: this.plant.code,
                        // today: moment(new Date()).format('YYYY-MM-DD'),
                        orderType: this.getOrderType(this.orderType)
                    }

                    let response = await VehicleService.countAssignmentsDeliveries(data)

                    this.countAssignments.countVehicleDeliveries = response.data.count_truck_trailer.doc_count
                    this.countAssignments.countTruckDeliveries = response.data.count_only_truck.doc_count
                    this.countAssignments.countOwnerDeliveries = response.data.count_owner.doc_count
                    this.countAssignments.countFWADeliveries = response.data.count_fwa.doc_count
                    this.countAssignments.countUnplannedDeliveries = response.data.count_unplanned.doc_count
                } else {
                    this.countAssignments.countVehicleDeliveries = 0
                    this.countAssignments.countTruckDeliveries = 0
                    this.countAssignments.countOwnerDeliveries = 0
                    this.countAssignments.countFWADeliveries = 0
                    this.countAssignments.countUnplannedDeliveries = 0
                }

            } catch (err) { console.error('ERROR COUNTING VEHICLES -->', err) }
        },
        async getVendorInfo(id) {
            try {
                let response = await VendorService.getVendor(id)
                if (response.data.hasOwnProperty('vendor') && response.data.vendor !== '') {
                    this.currentFWAgent = response.data;
                }
            } catch (err) { console.error('ERROR GETTING VENDOR INFO -->', err) }
        },
        async getDriverInfo(id) {
            try {
                let response = await DriverService.getDriverByIdMysql(id)
                if (response.data.length > 0 && response.data[0].length > 0) {
                    let driverFound = response.data[0][0];
                    this.currentDriver = {
                        driverNumberId: driverFound.MPQCId, address: { name: driverFound.internalDriverName },
                        phone: driverFound.phoneNumber, comments: driverFound.comments, id: driverFound.id
                    }
                } else {
                    console.log("No driver found")
                }
            } catch (err) { console.error('ERROR GETTING DRIVER INFO -->', err) }
        },
        editFWA() { this.currentFWAgent = null },
        assignFWAgent(fwa) {
            this.currentFWAgent = fwa
            this.fwaModal = false
        },
        showModalFWAgent() { this.fwaModal = true },
        checkWeighingExpiration(weighingDate, dateRange) {
            const inputDate = moment(weighingDate).format('YYYY-MM-DD');
            let expirationDate = moment(inputDate).add(Number(dateRange), 'days').toDate()
            expirationDate = moment(expirationDate, 'MMM DD YYYY HH:mm:ss ZZ').format('YYYY-MM-DD');
            const currentDate = new Date();
            const daysLeft = moment(expirationDate).diff(moment(currentDate), 'days');
            if ((this.$store.state.dispatch.entryWeight.manual && this.$store.state.dispatch.entryWeight.manual == true)
                || this.oneTimeTare) {
                this.expirationDateMessage = this.$t('One Time Weight')
                this.expirationDateMessageStatus = 'warning'
            } else {
                if (daysLeft > 7) {
                    this.expirationDateMessage = daysLeft + ' ' + this.$t('to tare exp')
                    this.expirationDateMessageStatus = 'success'
                } else if (daysLeft < 0) {
                    this.expirationDateMessage = 'Tare expired'
                    this.expirationDateMessageStatus = 'danger'
                    this.$store.commit("dispatch/setTareExpired", true);
                } else {
                    this.expirationDateMessage = daysLeft + ' ' + this.$t('to tare exp')
                    this.expirationDateMessageStatus = 'warning'
                }
            }
        },
        getOrderType(orderType) {
            if (this.isWaste) {
                switch (orderType) {
                    case 'Outbound':
                        return commonClasses.wasteOrderTypes.Outbound
                    case 'Inbound':
                        return commonClasses.wasteOrderTypes.Inbound
                    case 'Transfer In':
                        return commonClasses.wasteOrderTypes.Transfer
                    case 'Transfer Out':
                        return commonClasses.wasteOrderTypes.Transfer

                }
            } else {
                switch (orderType) {
                    case 'Outbound':
                        return commonClasses.orderTypes.Outbound
                    case 'Inbound':
                        return commonClasses.orderTypes.Inbound
                    case 'Transfer In':
                        return commonClasses.orderTypes.Transfer
                    case 'Transfer Out':
                        return commonClasses.orderTypes.Transfer

                }
            }
        },
        confirmActionNewOrder() {
            if (this.expirationDateMessageStatus === 'danger') {
                this.alertModalTare = true;
                return
            }

            this.$store.commit('setVehicle', this.currentTruck)
            this.$store.commit('setTrailer', this.currentTrailer)
            this.$store.commit('setFirstWeight', this.currentFirstWeight)
            this.$store.commit('setVendor', this.currentFWAgent)
            this.isWaste ? setTimeout(() => this.$router.push('/express-order?isWaste=true')) : setTimeout(() => this.$router.push('/express-order'))
        },
        showModalAlert() { this.alertModal = true },
        closeModalAlert(value) { value == 1 ? this.alertModal = false : this.alertModalTare = false },
        confirmAction() {
            this.alertModal = false
            this.loadAssignments = true;
        },
        showModalAssignmets(number) {
            // CHECK VALID TARE VEHICLE
            if (this.expirationDateMessageStatus === 'danger') {
                this.alertModalTare = true;
                return
            }

            switch (number) {
                case 1: // vehicle + trailer
                    if (this.countAssignments.countVehicleDeliveries > 0) {
                        this.assignmentType = 'vehicle'
                        this.assignmentTitle = this.$t('Assignments for vehicle')
                        this.assignmentData = {
                            orderType: this.getOrderType(this.orderType),
                            truckId: this.currentTruck.vehicleId,
                            trailerId: this.needTrailer ? this.currentTrailer.vehicleId : null
                        }
                        this.loadAssignments = true;
                    }
                    break;
                case 2: // only truck
                    if (this.countAssignments.countTruckDeliveries > 0) {
                        this.assignmentType = 'truck'
                        this.assignmentTitle = this.$t('Assignments for truck')
                        this.assignmentData = {
                            orderType: this.getOrderType(this.orderType),
                            truckId: this.currentTruck.vehicleId,
                            trailerId: this.needTrailer ? this.currentTrailer.vehicleId : null
                        }
                        this.loadAssignments = true;
                    }
                    break;
                case 3: // owner
                    if (this.countAssignments.countOwnerDeliveries > 0) {
                        this.assignmentType = 'owner'
                        this.assignmentTitle = this.$t('Assignments for owner')
                        this.assignmentData = {
                            orderType: this.getOrderType(this.orderType),
                            owner: this.currentTruck.carrierCode,
                            truckId: this.currentTruck.vehicleId,
                            trailerId: this.needTrailer ? this.currentTrailer.vehicleId : null
                        }

                        if (this.countAssignments.countTruckDeliveries > 0) {
                            this.showModalAlert()
                        } else {
                            this.loadAssignments = true;
                        }
                    }
                    break;
                case 4: // fwa
                    if (this.countAssignments.countFWADeliveries > 0) {
                        this.assignmentType = 'fwa'
                        this.assignmentTitle = this.$t('Assignments for forwarding agent')
                        this.assignmentData = {
                            orderType: this.getOrderType(this.orderType),
                            fwa: this.currentFWAgent.vendor,
                            owner: this.currentTruck.carrierCode
                        }

                        if (this.countAssignments.countTruckDeliveries > 0 || this.countAssignments.countOwnerDeliveries > 0) {
                            this.showModalAlert()
                        } else {
                            this.loadAssignments = true;
                        }
                    }
                    break;
                case 5: // last deliveries
                    this.assignmentType = 'last'
                    this.assignmentTitle = this.$t('Last deliveries')
                    this.assignmentData = {
                        orderType: this.getOrderType(this.orderType),
                        truckId: this.currentTruck.vehicleId,
                        trailerId: this.needTrailer ? this.currentTrailer.vehicleId : null
                    }
                    this.loadAssignments = true;
                    break;
                case 6: // Deliveries Unplanned
                    if (this.countAssignments.countUnplannedDeliveries > 0) {
                        this.assignmentType = 'unplanned'
                        this.assignmentTitle = this.$t('Unplanned deliveries')
                        this.assignmentData = {
                            orderType: this.getOrderType(this.orderType),
                            plantCode: this.plant.code,
                            truckId: this.currentTruck.vehicleId,
                            trailerId: this.needTrailer ? this.currentTrailer.vehicleId : null,
                            today: moment(new Date()).format('YYYY-MM-DD')
                        },
                            this.loadAssignments = true;
                    }
                    break;
            }
        },
        closeAssignmentModal() { this.loadAssignments = false },
        async AssignmentListdispatchAction(delivery, action) {
            this.closeAssignmentModal()
            this.$store.commit('dispatch/setToTareVehicle', false)
            if (this.orderType == this.orderTypeList.OUTBOUND || this.orderType == this.orderTypeList.TRANSFEROUT) {
                if (action == 'dispatch') {
                    let fullDelivery = await DeliveryService.getDelivery(delivery._id)
                    this.$store.commit('dispatch/setDelivery', fullDelivery.data[0])
                } else if (action == 'setTruck') {
                    let response = await this.updateTruckOfDelivery(delivery, 'none')
                    if (response.data.data.msg === "Updated") {
                        this.resetValues()
                        this.$router.push(`/deliveries?id=${delivery._id}`)
                    }
                } else { // action == 'link'
                    let response = await this.updateTruckOfDelivery(delivery, 'entry')
                    if (response.data.data.msg === "Updated") {
                        this.resetValues()
                        this.$router.push(`/deliveries?id=${delivery._id}`)
                    }
                }
                this.disableButtons = true
            } else if (this.orderType == this.orderTypeList.INBOUND) {
                this.$store.commit('dispatch/setDelivery', delivery)
            }
        },
        handleDispatchBtn() {
            switch (this.orderType) {
                case 'Outbound':
                    this.outboundDispatchAction(this.$store.state.dispatch.delivery)
                    break;
                case 'Trading':
                    this.outboundDispatchAction(this.$store.state.dispatch.delivery)
                    break;
                case 'Inbound':
                    this.inboundDispatchAction(this.$store.state.dispatch.delivery)
                    break
                case 'Transfer In':
                    this.transferInDispatchAction(this.$store.state.dispatch.delivery)
                    break
                case 'Transfer Out':
                    this.outboundDispatchAction(this.$store.state.dispatch.delivery)
                    break
            }
        },
        async outboundDispatchAction(delivery) {
            const alreadyHaveWasteInfo = delivery._source.material[0].wasteOutboundInfo
            let outboundWasteInfo = null

            if (this.isWaste && !alreadyHaveWasteInfo) {
                outboundWasteInfo = this.formatWasteInfoOutbound(delivery._source.material[0])

            } else if (this.isWaste && alreadyHaveWasteInfo) {
                outboundWasteInfo = delivery._source.material[0].wasteOutboundInfo
            }

            let id = delivery._id
            let materialsNetWeight = delivery._source.material.map(mat => ({
                primaryUomQty: mat.primaryUomQty,
                primaryUom: mat.primaryUom,
                baseUoMquantity: mat.primaryUom && mat.primaryUom !== "TM" ? mat.primaryUomQty : mat.baseUoMquantity,
                quantity: mat.quantity
            }));
            const deliveryUpdate = {
                id,
                vehicle: this.currentTruck,
                driver: this.currentDriver == null ? { id: "" } : this.currentDriver,
                vendor: this.currentFWAgent,
                trailer: this.currentTrailer,
                firstWeight: this.$store.getters['dispatch/getEntryWeight'],
                exitWeight: this.$store.getters['dispatch/getExitWeight'],
                direction: this.$store.state.dispatch.exitWeight.weight != null ? 'exit' : 'entry', // CAMBIAR SEGUN HAYA 1 o 2 peso entry or exit
                tare: this.$store.state.dispatch.netWeight != null ? this.$store.getters['dispatch/getNetWeight'] : this.$store.getters['dispatch/getDelivery']._source.netWeight,
                isPreloaded: false,
                orderType: this.getOrderType(this.orderType),
                notes: this.$store.getters['dispatch/getNotes'],
                hds_enable: false,
                loadingLocations: this.$store.getters['dispatch/getLoadingLocations'].length > 0 ? this.$store.getters['dispatch/getLoadingLocations'] : null,
                materialsNetWeight,
                plant: this.plant.code,
                entity: this.entity,
                isWaste: this.isWaste,
                cashSalePrice: this.$store.state.dispatch.cashSalePrice,
                deliveryCashSalePrices: this.$store.state.dispatch.deliveryCashSalePrices,
                paymentInfo: this.$store.state.dispatch.paymentInfo
            }
            if (outboundWasteInfo !== null) {
                deliveryUpdate.outboundWasteInfo = outboundWasteInfo
            }

            let localVehicle = await this.isLocalVehicle(deliveryUpdate);
            deliveryUpdate.vehicle.localVehicle = localVehicle;
            deliveryUpdate.vehicle.isLocal = localVehicle ? true : false;

            let loader = this.$loading.show();
            try {
                const updateDeliveryLocal = await DeliveryService.updateDeliveryLocal(deliveryUpdate, this.user)
                let deliveryNoteNumber = ''
                let deliveryNoteNumberBarcode = ''
                if (updateDeliveryLocal && updateDeliveryLocal.data) {
                    deliveryNoteNumber = updateDeliveryLocal.data.deliveryNoteNumber
                    deliveryNoteNumberBarcode = updateDeliveryLocal.data.deliveryNoteNumberBarcode
                    deliveryUpdate.deliveryNoteNumber = deliveryNoteNumber
                    deliveryUpdate.cnn = deliveryNoteNumber
                    deliveryUpdate.deliveryNoteNumberBarcode = deliveryNoteNumberBarcode
                }

                const updateDelivery = await DeliveryService.updateDelivery(deliveryUpdate, this.user)
                const attachmentsToUpload = this.$store.state.dispatch.attachmentsToUpload;
                const attachmentsToDelete = this.$store.state.dispatch.attachmentsToDelete;
                if (attachmentsToUpload.length > 0 || attachmentsToDelete.length > 0) {
                    await this.updateAttachments(delivery, attachmentsToUpload, attachmentsToDelete);
                }

                // ACK
                if (updateDelivery && updateDelivery.data) {
                    if (updateDelivery.data.data.msg === "Updated") {
                        // Sends ok to edge
                        const ackEdge = { entity: this.entity, ids: [id, updateDeliveryLocal.data.orderId] }
                        DataSyncService.sendAckToEdge(ackEdge, this.user)

                        // PDF
                        this.notificationAndCreatePDF(deliveryUpdate.direction, id)

                        this.$notify({
                            title: this.$t('Outbound Updated'),
                            text: this.$t('Outbound Updated'),
                            type: 'success',
                            duration: 3000
                        });

                        this.$router.push("/deliveries")
                    } else {
                        this.$notify({
                            title: this.$t('Error updating Inbound'),
                            type: 'error',
                            duration: -1,
                            closeOnClick: true
                        })
                    }
                }
                this.resetValues();
            } catch (err) {
                let sqlMessage = '';
                if (err.msg && err.msg.code == 'ER_SIGNAL_EXCEPTION') {
                    sqlMessage = err.msg.sqlMessage;
                }
                this.$notify({
                    title: this.$t('Error updating Outbound'),
                    text: this.$t(sqlMessage),
                    type: 'error',
                    duration: -1,
                    closeOnClick: true
                })
                console.error(err)
            } finally { loader.hide() }
        },
        async inboundDispatchAction(delivery) {
            const alreadyHaveWasteInfo = delivery._source.material[0].wasteInfo

            if (this.isWaste && !alreadyHaveWasteInfo) {
                delivery._source.material[0].dap = this.formatWasteInfoInbound(delivery._source.material[0].dap)
            }

            // Add to delivery:
            let id = delivery._id
            let materialsNetWeight = delivery._source.material.map(mat => ({
                primaryUomQty: mat.primaryUomQty,
                primaryUom: mat.primaryUom,
                baseUoMquantity: mat.primaryUom && mat.primaryUom !== "TM" ? mat.primaryUomQty : mat.baseUoMquantity,
                quantity: mat.quantity
            }));
            const deliveryUpdate = {
                id,
                vehicle: this.currentTruck,
                driver: this.currentDriver == null ? { id: "" } : this.currentDriver,
                vendor: this.currentFWAgent,
                trailer: this.currentTrailer,
                firstWeight: this.$store.getters['dispatch/getEntryWeight'],
                exitWeight: this.$store.state.dispatch.exitWeightNeeded ? this.$store.getters['dispatch/getExitWeight'] : this.currentFirstWeight,
                direction: this.$store.state.dispatch.exitWeightNeeded && this.$store.state.dispatch.exitWeight.weight <= 0 ? 'entry' : 'exit',
                tare: this.$store.state.dispatch.netWeight != null ? this.$store.getters['dispatch/getNetWeight'] : this.$store.getters['dispatch/getDelivery']._source.netWeight,
                isPreloaded: false,
                orderType: this.getOrderType(this.orderType),
                notes: this.$store.getters['dispatch/getNotes'],
                hds_enable: false,
                loadingLocations: this.$store.getters['dispatch/getLoadingLocations'].length > 0 ? this.$store.getters['dispatch/getLoadingLocations'] : null,
                materialsNetWeight,
                plant: this.plant.code,
                entity: this.entity,
                declaredNetWeight: this.$store.getters['dispatch/getDeclaredNetWeight'],
                exitWeightNeeded: this.$store.getters['dispatch/getExitWeightNeeded'],
                isWaste: this.isWaste,
                wasteMaterialInfo: this.isWaste && alreadyHaveWasteInfo
                    ? delivery._source.material[0].secondWeightDataInbound
                    : delivery._source.material[0].dap,
                cashSalePrice: this.$store.state.dispatch.cashSalePrice,
                deliveryCashSalePrices: this.$store.state.dispatch.deliveryCashSalePrices,
                paymentInfo: this.$store.state.dispatch.paymentInfo
            }

            // Update Delivery
            let loader = this.$loading.show();
            try {
                const updateDeliveryLocal = await DeliveryService.updateDeliveryLocal(deliveryUpdate, this.user)
                let deliveryNoteNumber = ''
                let deliveryNoteNumberBarcode = ''
                if (updateDeliveryLocal && updateDeliveryLocal.data) {
                    deliveryNoteNumber = updateDeliveryLocal.data.deliveryNoteNumber
                    deliveryNoteNumberBarcode = updateDeliveryLocal.data.deliveryNoteNumberBarcode
                    deliveryUpdate.deliveryNoteNumber = deliveryNoteNumber
                    deliveryUpdate.cnn = deliveryNoteNumber
                    deliveryUpdate.deliveryNoteNumberBarcode = deliveryNoteNumberBarcode
                }

                let response = await DeliveryService.updateDelivery(deliveryUpdate)
                const attachmentsToUpload = this.$store.state.dispatch.attachmentsToUpload;
                const attachmentsToDelete = this.$store.state.dispatch.attachmentsToDelete;
                if (attachmentsToUpload.length > 0 || attachmentsToDelete.length > 0) {
                    await this.updateAttachments(delivery, attachmentsToUpload, attachmentsToDelete);
                }
                if (response.data.data.msg === 'Updated') {
                    // Sends ok to edge
                    const ackEdge = { entity: this.entity, ids: [id, updateDeliveryLocal.data.orderId] }
                    DataSyncService.sendAckToEdge(ackEdge, this.user)

                    // PDF
                    this.notificationAndCreatePDF(deliveryUpdate.direction, id)

                    this.$notify({
                        title: this.$t('Inbound Updated'),
                        text: this.$t('Inbound Updated'),
                        type: 'success',
                        duration: 3000
                    });
                } else {
                    this.$notify({
                        title: this.$t('Error updating Inbound'),
                        type: 'error',
                        duration: -1,
                        closeOnClick: true
                    })
                }
                this.resetValues();
            } catch (err) {
                let sqlMessage = '';
                if (err.msg && err.msg.code == 'ER_SIGNAL_EXCEPTION') {
                    sqlMessage = err.msg.sqlMessage;
                }
                this.$notify({
                    title: this.$t('Error updating Inbound'),
                    text: this.$t(sqlMessage),
                    type: 'error',
                    duration: -1,
                    closeOnClick: true
                })
                console.error(err)
            } finally { loader.hide() }
        },
        async transferInDispatchAction(delivery) {
            let transferInWasteInfo = null

            const alreadyHaveWasteInfo = delivery._source.material[0].wasteOutboundInfo

            if (this.isWaste && !alreadyHaveWasteInfo) {
                transferInWasteInfo = this.formatWasteInfoTransferIn(delivery._source.material[0])
            } else if (this.isWaste && alreadyHaveWasteInfo) {
                transferInWasteInfo = delivery._source.material[0].wasteOutboundInfo
            }

            let id = delivery._id
            const deliveryUpdate = {
                id,
                status: 'Arrived',
                notes: this.$store.getters['dispatch/getNotes'],
                loadingLocations: this.$store.getters['dispatch/getLoadingLocations'].length > 0 ? this.$store.getters['dispatch/getLoadingLocations'] : null,
                isWaste: this.isWaste,
                transferInWasteInfo: this.isWaste && transferInWasteInfo !== null ? transferInWasteInfo : {},
            }
            let loader = this.$loading.show();
            try {
                const updateDeliveryLocal = await DeliveryService.updateDeliveryLocal(deliveryUpdate, this.user)
                let deliveryNoteNumber = ''
                let deliveryNoteNumberBarcode = ''
                if (updateDeliveryLocal && updateDeliveryLocal.data) {
                    deliveryNoteNumber = updateDeliveryLocal.data.deliveryNoteNumber
                    deliveryNoteNumberBarcode = updateDeliveryLocal.data.deliveryNoteNumberBarcode
                    deliveryUpdate.deliveryNoteNumber = deliveryNoteNumber
                    deliveryUpdate.cnn = deliveryNoteNumber
                    deliveryUpdate.deliveryNoteNumberBarcode = deliveryNoteNumberBarcode
                }

                let response = await DeliveryService.updateTransferInDeliveryData(deliveryUpdate)

                const attachmentsToUpload = this.$store.state.dispatch.attachmentsToUpload;
                const attachmentsToDelete = this.$store.state.dispatch.attachmentsToDelete;
                if (attachmentsToUpload.length > 0 || attachmentsToDelete.length > 0) {
                    await this.updateAttachments(delivery, attachmentsToUpload, attachmentsToDelete);
                }

                if (response.data.msg === 'Updated') {
                    // Sends ok to edge
                    const ackEdge = { entity: this.entity, ids: [id, updateDeliveryLocal.data.orderId] }
                    DataSyncService.sendAckToEdge(ackEdge, this.user)

                    // PDF
                    this.notificationAndCreatePDF('exit', id)

                    this.$notify({
                        title: this.$t('Transfer In Updated'),
                        text: this.$t('Transfer In Updated'),
                        type: 'success',
                        duration: 3000
                    });
                } else {
                    this.$notify({
                        title: this.$t('Error updating Transfer In'),
                        type: 'error',
                        duration: -1,
                        closeOnClick: true
                    })
                }
                this.resetValues();
            } catch (err) {
                let sqlMessage = '';
                if (err.msg && err.msg.code == 'ER_SIGNAL_EXCEPTION') {
                    sqlMessage = err.msg.sqlMessage;
                }
                this.$notify({
                    title: this.$t('Error updating Transfer In'),
                    text: this.$t(sqlMessage),
                    type: 'error',
                    duration: -1,
                    closeOnClick: true
                })
                console.error(err)
            } finally { loader.hide() }
        },
        formatWasteInfoInbound(wasteInfo) {
            return {
                ...wasteInfo,
                dapInfo: {
                    erpCode: wasteInfo.erpCode,
                    estimateTonnage: wasteInfo.estimateTonnage,
                    startValidationDate: wasteInfo.startValidationDate instanceof Date
                        ? moment(wasteInfo.startValidationDate).format('YYYY-MM-DD')
                        : wasteInfo.startValidationDate,
                    firstDeliveryDate: wasteInfo.firstDeliveryDate instanceof Date
                        ? moment(wasteInfo.firstDeliveryDate).format('YYYY-MM-DD')
                        : wasteInfo.firstDeliveryDate,
                    topology: wasteInfo.topology,
                    from: wasteInfo.from,
                },
                fireQ: wasteInfo.fireQ,
                destinationType: wasteInfo.destinationType,
                destinationInfo: wasteInfo.destinationInfo,
                treatmentInfo: wasteInfo.treatmentInfo,

                control: wasteInfo.control
            }
        },
        formatWasteInfoOutbound(wasteInfo) {
            if (!wasteInfo.fireQOutbound
                && !wasteInfo.originOutbound
                && !wasteInfo.treatmentOutbound
                && !wasteInfo.qualificationOutbound
                && !wasteInfo.referenceOutbound) {
                return null
            }
            const { locationOutbound } = wasteInfo

            const wasteFormated = {
                fireQ: wasteInfo.fireQOutbound,
                originInfo: wasteInfo.originOutbound,
                treatmentInfo: wasteInfo.treatmentOutbound,
                qualification: wasteInfo.qualificationOutbound,

                references: wasteInfo.referenceOutbound,
            }

            if (locationOutbound) {
                wasteFormated.location = {
                    address: locationOutbound ? locationOutbound.address : '',
                    region: locationOutbound ? locationOutbound.region : '',
                    postal_code: locationOutbound ? locationOutbound.postal_code : '',
                    city: locationOutbound ? locationOutbound.city : '',
                    lat: locationOutbound ? locationOutbound.lat : '',
                    lon: locationOutbound ? locationOutbound.lon : '',
                    sheet: locationOutbound ? locationOutbound.sheet : '',
                    inseeCode: locationOutbound ? locationOutbound.inseeCode : '',
                    section: locationOutbound ? locationOutbound.section : ''
                }
            }

            return wasteFormated
        },
        formatWasteInfoTransferIn(wasteInfo) {
            return {
                fireQ: wasteInfo.fireQOutbound,
                originInfo: wasteInfo.originOutbound,
                treatmentInfo: wasteInfo.treatmentOutbound,
                qualification: wasteInfo.qualificationOutbound,
                // references: wasteInfo.referenceOutbound,
            }
        },
        async cloneOrder(delivery) {
            if (delivery._source.status !== 'Loading') {
                // Clone EXW delivery
                if (delivery._source.incoterm == 'EXW') {
                    try {
                        // this.$store.commit('setOrderClonedFrom', delivery._source.OrderNo)
                        // this.$store.commit('setDeliveryClonedFrom', delivery._source.deliveryNO)
                        // CHECK VEHICLE
                        if (delivery._source.logistic.truck.vehicleId && delivery._source.logistic.truck.vehicleId.length > 0) {
                            this.$store.commit('setVehicle', { vehicleId: delivery._source.logistic.truck.vehicleId })
                        }
                        // CHECK TRAILER
                        if (delivery._source.logistic.trailer.vehicleId && delivery._source.logistic.trailer.vehicleId.length > 0) {
                            this.$store.commit('setTrailer', { vehicleId: delivery._source.logistic.trailer.vehicleId })
                        }
                        // TO ROUTE
                        // this.$router.push('/express-order') 
                        this.$router.push(`/express-order?clone=${delivery._source.OrderNo}`)
                        // delivery._source.express && delivery._source.express === true ? this.$router.push('/express-order') : this.$router.push('/new-order')

                    } catch (err) { console.error('ERROR WITH EXW -->', err) }
                    // Clone DDP Order
                } else if (delivery._source.incoterm == 'DDP') {
                    try {
                        // get order id 
                        let order = await OrderService.getOrderByON(delivery._source.OrderNo)
                        // route to 
                        if (order.data && order.data.length > 0)
                            this.$router.push(`/new-order?id=${order.data[0]._id}&action=clone`)

                    } catch (err) { console.error('ERROR GETTING ORDER BY ON -->', err) }
                }
            } else {
                this.$notify({
                    title: this.$t('Cannot clone orders with "Loading" status'),
                    type: 'error',
                    duration: -1,
                    closeOnClick: true
                })
            }
        },
        async entryPlant() {
            const transitData = {
                vehicle: {
                    vehicleId: this.currentTruck.vehicleId,
                    licensePlateNumber: this.currentTruck.licensePlateNumber
                },
                // driver: this.currentDriver == null ? { id: "" } : this.currentDriver,
                // vendor: this.currentFWAgent,
                trailer: {
                    vehicleId: this.currentTrailer.vehicleId,
                    licensePlateNumber: this.currentTrailer.licensePlateNumber
                },
                firstWeight: this.currentFirstWeight,
                // tare: transformToUnit(this.currentFirstWeight.weight, this.entryExitWeightUnit, this.shownWeighingUOM),
                plant: this.plant.code
            }
            try {
                await TransitService.createEmtyTransit(transitData)
                this.$notify({
                    title: this.$t("Transit created"),
                    text: `Transit created for ${this.currentTruck.licensePlateNumber}`,
                    type: 'success',
                });
                this.resetValues()
            } catch (err) {
                console.error('ERROR WITH TRANSITS -->', err)
                this.$notify({
                    title: this.$t("Transit created"),
                    text: `Truck already in transit`,
                    type: 'error',
                });
                return false
            }
        },
        resetValues() {
            this.editTruck()
            this.currentDriver = null
            this.trucks = []
            this.trailers = []
            this.vehiclesModal = false
            this.trailersModal = false
            this.loadAssignments = false
            this.dispatchDelivery = null
            this.deliveryModal = false
            this.assignmentData = null
            this.showData = false
            this.disableButtons = false
            this.$store.commit('dispatch/resetValues')
        },
        editTruck() {
            this.showData = false
            this.currentTruck = null
            this.currentTrailer = null
            this.currentFWAgent = null
            this.needTrailer = false
            this.currentFirstWeight = {
                date: null,
                weight: null,
                weightBridge: null,
                manual: false,
                user: {
                    userID: null,
                    userName: null
                }
            }
            this.countAssignments = {
                countVehicleDeliveries: null,
                countTruckDeliveries: null,
                countOwnerDeliveries: null,
                countFWADeliveries: null,
                countUnplannedDeliveries: null
            }
            this.dateFirstWeight = null
            this.$store.commit('dispatch/setCurrentTruck', this.currentTruck)
            this.$store.commit('dispatch/setCurrentTrailer', this.currentTrailer)
        },
        editTrailer() {
            this.currentTrailer = null
            this.showData = false
            this.needTrailer = true
        },
        async updateTruckOfDelivery(delivery, direction) {
            const deliveryUpdate = {
                id: delivery._id,
                vehicle: this.currentTruck,
                driver: this.currentDriver == null ? { id: "" } : this.currentDriver,
                vendor: this.currentFWAgent,
                trailer: this.currentTrailer,
                firstWeight: direction == 'none' ? {} : this.currentFirstWeight,
                exitWeight: {},
                direction,
                tare: transformToUnit(this.currentFirstWeight.weight, this.entryExitWeightUnit, this.shownWeighingUOM),
                isPreloaded: false,
                orderType: delivery._source.orderType,
                notes: {},
                hds_enable: false,
                loadingLocations: null,
                materialsNetWeight: null,
                plant: this.plant.code,
                entity: this.entity,
                cashSalePrice: this.$store.state.dispatch.cashSalePrice,
                paymentInfo: this.$store.state.dispatch.paymentInfo
            }

            try {
                const updateDelivery = await DeliveryService.updateDelivery(deliveryUpdate, this.user)
                return updateDelivery

            } catch (err) {
                console.error('ERROR UPDATING TRUCK OF DELIVERY --> ', err)
                return false
            }
        },
        async updateAttachments(delivery, attachmentsToUpload, attachmentsToDelete) {
            try {
                if (attachmentsToUpload.length > 0) {
                    const country = delivery._source.soldto.country;
                    const deliveryYear = moment(delivery._source.deliveryLocalDate).format('YYYY');
                    const deliveryNo = delivery._source.deliveryNO;
                    const attachmentsS3Prefix = `${country}/deliveryAttach/${deliveryYear}/${deliveryNo}`;

                    const filesUploaded = await FileHelper.uploadAttachments(attachmentsToUpload, 'delivery', deliveryNo, attachmentsS3Prefix);
                    if (!delivery._source.attachments) {
                        delivery._source.attachments = [];
                    }
                    for (let file of attachmentsToUpload) {
                        // Only the attachments uploaded successfully to S3 are added to the delivery
                        const uploadedFile = filesUploaded.find(f => f.name === FileHelper.sanitizeFilename(file.file.name));
                        if (uploadedFile) {
                            delivery._source.attachments.push({
                                filename: uploadedFile.name,
                                filepath: uploadedFile.path,
                                filesize: file.file.size,
                                comments: file.comments,
                                category: file.category
                            });
                        }
                    }

                }
                if (attachmentsToDelete.length > 0) {
                    delivery._source.attachments = delivery._source.attachments.filter(a => !attachmentsToDelete.some(file => file.filename == a.filename));
                }
                await DeliveryService.updateDeliveryAttachments(delivery);
                this.$store.commit('dispatch/setAttachmentsToUpload', []);
                this.$store.commit('dispatch/setAttachmentsToDelete', []);
            } catch (error) {
                console.error(error);
            }
        },
        createPDF(delivery) { DeliveryNoteService.createDN(delivery) },
        notificationAndCreatePDF(direction, id) {
            let text = '';
            if (direction === 'entry') { // CHECK DIRECTION
                text = this.$t("First Weight Saved")
            } else if (direction === 'exit') {
                const printer = (this.user.terminal ? this.user.terminal.printers : false) ? this.user.terminal.printers.find((p) => p.code === "DN_EPOD" || p.code === "DN") : false
                if (printer) { this.createPDF({ delivery: { id }, user: this.user, printer }) }
                else {
                    this.$notify({
                        title: this.$t("Printer not found"),
                        text: `Printer not found in user profile. Please check your profile settings`,
                        type: 'warning',
                    });
                }
                text = this.$t("Exit Weight Saved")
            } else { text = this.$t("Delivery Information Saved") }
            this.$notify({
                title: this.$t("Data Saved"),
                text: `${text}`,
                type: 'success',
                duration: 3000
            });
        },
        async isLocalVehicle(delivery) { //IF IS A LOCAL VEHICLE CHANGE THE SAP DELIVERY NOTE STATUS
            return new Promise(async (resolve, reject) => {
                try {
                    await VehicleService.getVehicleById(delivery.vehicle.vehicleId).then(async (resp) => {
                        let localVehicle = resp.data[0].isLocal;
                        if (localVehicle == 1) {
                            await DeliveryService.updateSAPDeliveryNote('hold-veh', delivery.id)
                                .then(resp => { console.log('RESP DELIVERY SAP UPDATED -->', resp) })
                                .catch(err => { console.log('ERR UPDATE DELIVERY SAP UPDATE --->', err) })
                        }
                        resolve(localVehicle);
                    });
                } catch (err) { reject(err) }
            });
        },
        resetDispatch() {
            this.$store.commit('dispatch/resetValues')
            this.resetValues()
        },
        async getVehicleType() {
            let vehicleTypes = await VehicleService.getVehicleTypesOpts(this.country);
            let vehicleType = vehicleTypes.data.filter(x =>
                x.vehicleType == this.currentTruck.details.category.vehicleType || x.description == this.currentTruck.details.category.vehicleType);
            this.currentTruck.details.category.vehicleType = vehicleType[0].description;

            if (this.currentTrailer != null) {
                let trailerType = vehicleTypes.data.filter(x =>
                    x.vehicleType == this.currentTrailer.details.category.vehicleType || x.description == this.currentTrailer.details.category.vehicleType);
                this.currentTrailer.details.category.vehicleType = trailerType[0].description;
            }
        }
    },
    computed: {
        isPreloaded() {
            return this.$store.state.dispatch.isPreloaded
        },
        orderType() { return this.$store.state.dispatch.orderType },
        isWaste() { return this.$store.state.dispatch.isWaste },
        toWaste() {
            // return this.$route.query.toWaste && this.$route.query.toWaste == 'true'
            return this.$route.query.toWaste
                ? { value: JSON.parse(this.$route.query.toWaste), vehicleId: this.$route.query.vehicle, trailerId: this.$route.query.trailer }
                : null
        },
        oneTimeTare() { return this.$store.state.dispatch.oneTimeTare },
        checkAssignmentsShow() {
            return this.$store.state.dispatch.delivery == null
            // || (this.$store.state.dispatch.delivery != null && this.orderType == 'Outbound')
        },
        checkDDPButtonAvailable() {
            // Only enable if no assigmnents and FWA != EXWORKS 
            // if(this.orderType != 'Outbound') return false

            if (this.currentFWAgent != null) {

                return (this.countAssignments.countVehicleDeliveries > 0
                    || this.countAssignments.countTruckDeliveries > 0
                    || this.countAssignments.countOwnerDeliveries > 0
                    || this.countAssignments.countFWADeliveries > 0)
                    // || this.countAssignments.countUnplannedDeliveries > 0) 
                    || (this.currentFWAgent.vendor == "88888888")
                    ? true
                    : false
            } else {

                return (this.countAssignments.countVehicleDeliveries > 0
                    || this.countAssignments.countTruckDeliveries > 0
                    || this.countAssignments.countOwnerDeliveries > 0
                    || this.countAssignments.countFWADeliveries > 0)
                    // || this.countAssignments.countUnplannedDeliveries > 0)
                    ? true
                    : false
            }
        }
    },
    watch: {
        checkDDPButtonAvailable(newVal, oldVal) { this.exwork = newVal },
        orderType(newVal, oldVal) {
            if (this.currentTruck != null) { this.countDeliveries() }
            // newVal == 'Outbound' ? this.$store.commit('dispatch/setDelivery', {}) : this.$store.commit('dispatch/setDelivery', null)
            if (!this.isPreloaded) {
                this.$store.commit('dispatch/setDelivery', null)
            }

        },
        '$store.state.dispatch.entryWeight'(newVal, oldVal) {
            if ((this.orderType === this.orderTypeList.OUTBOUND || this.orderType === this.orderTypeList.TRANSFEROUT)
                && newVal.date != null
                && this.$store.state.dispatch.delivery == null) {

                this.currentFirstWeight = newVal
                this.dateFirstWeight = this.currentFirstWeight.date
                this.checkWeighingExpiration(this.currentFirstWeight.date, this.weighingExpirationRange);
                this.$store.commit("dispatch/setTare", transformToUnit(this.currentFirstWeight.weight, 'KGM', this.shownWeighingUOM));
            }
        },
        '$store.state.dispatch.exitWeight'(newVal, oldVal) { // CHEQUEAR EN QUE CASOS SI
            if (newVal != null) {
                this.currentFirstWeight = newVal
                this.dateFirstWeight = this.currentFirstWeight.date
                this.checkWeighingExpiration(this.currentFirstWeight.date, this.weighingExpirationRange);
            }
        },
        '$store.state.dispatch.dispatchAction'(newVal, oldVal) {
            if (newVal == true) { this.handleDispatchBtn() }
        },
        async '$store.state.dispatch.delivery'(newVal) {

            // Delivery validations
            if (
                !this.$store.state.dispatch.isPreloaded ||
                newVal == null
            ) {

                return
            }

            try {


                // Truck validations
                if (
                    typeof newVal._source.logistic.truck.vehicleId == "undefined" ||
                    !newVal._source.logistic.truck.vehicleId
                ) {
                    return
                }

                // Truck fetch
                const truckLoader = this.$loading.show()

                const response = await VehicleService.getVehicle(newVal._source.logistic.truck.vehicleId)

                if (response.data instanceof Array) {
                    truckLoader.hide()
                    throw new Error("Vehicle Not found.")
                }

                // Truck loading
                const truck = response.data
                this.setTruckFromModal({
                    ...truck,
                    ...truck.technicalInfo,
                })

                truckLoader.hide()

                this.$notify({
                    title: "Vehicle loaded.",
                    text: `Vehicle ${truck.vehicleId} loaded succesfully.`,
                    type: 'success',
                    duration: 3000
                });
            } catch (error) {
                this.$notify({
                    title: "Error.",
                    text: `Error loading vehicle ${newVal._source.logistic.truck.vehicleId}.`,
                    type: 'success',
                    duration: 3000
                });
            }


            try {
                // Trailer validation
                if (
                    !this.needTrailer ||
                    typeof newVal._source.logistic.trailer.vehicleId == "undefined" ||
                    !newVal._source.logistic.trailer.vehicleId
                ) {

                    return
                }

                // Trailer fetch
                const trailerLoader = this.$loading.show()

                let responseTrailer = await VehicleService.getVehicle(newVal._source.logistic.trailer.vehicleId)

                if (responseTrailer.data instanceof Array) {
                    trailerLoader.hide()
                    throw new Error("Vehicle Not found.")
                }

                // Trailer loading
                const trailer = responseTrailer.data
                this.setTrailerFromModal({
                    ...trailer,
                    ...trailer.technicalInfo,
                })

                trailerLoader.hide()

                this.$notify({
                    title: "Trailer loaded.",
                    text: `Trailer ${trailer.vehicleId} loaded succesfully.`,
                    type: 'success',
                    duration: 3000
                });
            } catch (error) {
                this.$notify({
                    title: "Error.",
                    text: `Error loading vehicle ${newVal._source.logistic.trailer.vehicleId}.`,
                    type: 'success',
                    duration: 3000
                });
            }
        }
    }
}
</script>

<style lang="scss">
.control__input {
    display: flex;

    .form-control+.form-control {
        margin-left: 4rem;
    }

    .seeker.vehicle {
        width: auto;
    }
}

.form-control:nth-of-type(2) {
    margin-top: 1rem;
}

.columns {
    &.assign {
        .form-control__label {

            .text {
                padding-top: 0.8rem;
            }

            .p-inputtext {
                width: 2rem;
                text-align: center;
                height: 2rem;
                margin-top: 10px;

                &.numberData {
                    cursor: pointer;
                    padding: 5px 5px 0 5px;
                    background-color: #1D4370;
                    color: white;
                    width: 3rem;
                    height: 2.5rem;
                }
            }
        }
    }
}

.form-control {
    &.down {
        margin-top: 10px;
    }
}

.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.modalAssigments {
    --width: 110rem;

    .dialog__title {
        margin-left: 2rem
    }
}

.disabledButton {
    pointer-events: none;
    cursor: not-allowed;
    background-color: lightgray !important;
    color: black !important;
}

.dataTabs {
    .p-button.p-component {
        width: 80px;
        height: 50px;
        border: 1px solid lightgray;
    }

    div:nth-child(2) {
        margin-left: 10px;
    }

    .disabledTabs {
        background-color: lightgray;
        height: 100%;
        padding: 12px 0;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.columns.weightDialog {
    width: 70rem;

    .column {
        .column {
            padding: 0;
        }
    }
}

.alertModal {
    padding: 0 2rem 1rem;

    .alertButtons {
        padding: 2rem 10rem;
        display: flex;
        justify-content: space-evenly;
    }
}

.expirationDateContainer {
    .p-badge.p-component.p-badge-lg.weighModal {
        &.p-badge-success {
            height: 70%;
            color: #22C55E;
            border: 1px solid #22C55E;
            background-color: white;
        }

        &.p-badge-warning {
            height: 70%;
            color: #F97316;
            border: 1px solid #F97316;
            background-color: white;
        }

        &.p-badge-danger {
            height: 70%;
            color: #EF4444;
            border: 1px solid #EF4444;
            background-color: white;
        }
    }
}

.p-dialog.p-component {
    .p-dialog-content {
        .app-content {
            padding: 0 2rem;

            .app-content__content.cl-content {
                padding-top: 0;
                height: auto;
            }
        }
    }
}
</style>