<template>
  <Step :title="$t('Materials')" class="materials-step">
    <!-- TABLE HEADER -->
    <div class="columns materials-step__table-header">
      <div class="column">
        {{ $t('Material') }}
      </div>
      <div class="column">
        {{ $t('Material Code') }}
      </div>
      <div class="column">
        {{ $t('Ord. Qty') }}
      </div>
      <div class="column">
        {{ $t('Net. Qty') }}
      </div>
      <!--div class="column">
        {{ $store.state.dispatch.orderType == 'Outbound' || $store.state.dispatch.orderType == 'Transfer Out' ? $t('Loading Location') : $t('Unloading Location') }}
      </div-->
    </div>

    <!-- TABLE BODY -->
    <div v-if="delivery">
      <div v-for="(material, originalIndex) in sortedMaterials" class="columns">
        <div class="column">
          {{ materialDescription(material.descriptions, locale) }}
        </div>
        <div class="column">
          {{ material.material && material.material.id ? material.material.id : material.materialID }}
        </div>
        <div class="column">
          {{ material.primaryUom 
              ? (material.primaryUomQty + ' ' + material.primaryUom) 
              : (material.baseUoMquantity + ' ' + (!material.baseUoM ? 'TM' : WeightUnit.getUnitISOCode(material.baseUoM, unitsOfMeasure))) }}
        </div>
        <div class="column">
          {{ (material.baseUoMquantity + ' ' + WeightUnit.getUnitISOCode(material.baseUoM || unitMeasurement || 'TNE', unitsOfMeasure)) }}
        </div>
        <!--div class="column">
          <Dropdown v-model="materialsLoadingLocations[originalIndex]" :options="loadingLocations" optionLabel="description" 
            optionvalue="code" :placeholder=" $t('Select location')" @change="updateLoadingLocation"/>
        </div-->
      </div>
    </div>
    <!-- EMPTY TABLE BODY -->
    <div v-else class="columns">
      <div class="column">
        {{ emptyMaterialRow.description }}
      </div>
      <div class="column">
        {{ emptyMaterialRow.materialID }}
      </div>
      <div class="column">
        {{ emptyMaterialRow.primaryUomQty }}
      </div>
      <div class="column">
        {{ emptyMaterialRow.baseUoMquantity }}
      </div>
      <div class="column">
        <Dropdown v-model="emptyMaterialRow" :options="[]" optionLabel="description" optionvalue="code" 
          :placeholder="$t('Select a unloading location')" disabled/>
      </div>
    </div>
  </Step>
</template>

<script setup>
import countriesService from '@/services/api/countries';
import plantsService from '@/services/api/plants';
import * as WeightUnit from '../../../helper/weightUnit';
import { materialDescription } from '../../../helper/textMaterials';

import Dropdown from 'primevue/dropdown';
import Step from "@/components/dispatch/order/utils/step";
</script>

<script>
export default {
  components: {
    Step,
    Dropdown
  },
  emits: ['update:loadingLocation'],
  data: function () {
    return {
      plant: null,
      locale: this.$locale,
      emptyMaterialRow: {
        materialID: '-',
        primaryUomQty: '-',
        baseUoMquantity: '-',
        description: '-',
        loadingLocation: ''
      },
      unitsOfMeasure: [],
      loadingLocations: [],
      materialsLoadingLocations: []
    }
  },
  created() {
    this.fillMaterialsInfo(this.delivery);
  },
  computed: {
    delivery() {
      return this.$store.state.dispatch.delivery;
    },
    sortedMaterials() {
      if (this.delivery != null) {
        const delivery = this.delivery._source;
        return [...delivery.material].map((mat, i) => ({...mat, originalIndex: i})).sort((mat1, mat2) => {
          if (mat1.position && mat2.position) {
            return mat1.position - mat2.position
          } else if(mat1.externalId && mat2.externalId) {
            return mat1.externalId.localeCompare(mat2.externalId);
          } else {
            return 0;
          }
        });
      } else {
        return []
      }
		}
  },
  mounted() {
    let plant = JSON.parse(sessionStorage.getItem("user")).plant;
		this.getFullPlant(plant.id);
    this.getUoms();
  },
  methods: {
    getUoms() {
			countriesService.getCountryLovByName('MEASUREMENT UNITS').then(response => {
				if (response.data.length > 0) { this.unitsOfMeasure = response.data }
			}).catch(error => {
        console.log(error);
      })
		},
    getFullPlant(id) {
			plantsService.getFullPlant(id).then((response) => {
				this.plant = response.data.plant;
				this.loadingLocations = this.plant.loadingLines.filter(ll => ll.active == 1);
			}).catch(error => {
        console.log(error);
      });
		},
    fillMaterialsInfo(delivery) {
      if (delivery) {
        delivery._source.material.forEach((m, index) => {
          if (m.loadingLocationDescription != undefined) {
            this.materialsLoadingLocations[index] = this.loadingLocations.find((ll) => ll.description == m.loadingLocationDescription);
          }
        });
      }
		},
    updateLoadingLocation() {
      this.$store.commit("dispatch/setLoadingLocations", this.materialsLoadingLocations);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/hodim/index.scss';
@import '@/assets/css/lh-ds/core/functions';

.materials-step {
  &__table-header {
    font-size: 1rem;
    font-weight: bold;
  }

  &.overlay::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
  } 
}
</style>